import React, { Component } from "react";
import { Icon, Label, Button } from "semantic-ui-react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export default class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      selectedImage: this.props.selectedImage || null,
      index: this.props.index,
      selectedImageType: this.props.selectedImageType || null,
      selectedImageName: this.props.selectedImageName || null,
      cropImageSrc: null,
      crop: {
        unit: '%',
        width: 50,
        height: 50,
        aspect: 1/1
      }
    };
    this.imageUploadContainerRef = React.createRef();
    this.hiddenInputRef = React.createRef();
    this.selectImages = this.selectImages.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.onImageLoaded = this.onImageLoaded.bind(this);
    this.handleCropClick = this.handleCropClick.bind(this);
    this.handleCropCancelClick = this.handleCropCancelClick.bind(this);
    this.makeClientCrop = this.makeClientCrop.bind(this);
  }

  componentDidMount() {
    this.setState({ selectedImage: this.props.selectedImage});
  }

  selectImages(e) {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      
      reader.addEventListener('load', () =>
        this.setState({ selectedImage: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
      this.setState({ selectedImageName: e.target.files[0].name, selectedImageType: e.target.files[0].type })
    }
  }

  onCropComplete(crop) {
    this.setState({ crop });
  }

  onCropChange(crop) {
    this.setState({ crop });
  }

  onImageLoaded(image) {
    this.cropImageRef = image;
    this.setState({ crop: {
      unit: '%',
      width: 100,
      aspect: 1/1,
    }});
    return false; // Return false when setting crop state in here.
  }

  async handleCropClick(e) {
    e.preventDefault();
    await this.makeClientCrop(this.state.crop);
    if (this.imageUploadContainerRef.current) {
      this.imageUploadContainerRef.current.scrollIntoView({ 
         behavior: "smooth", 
         block: "nearest"
      });
    }
  }

  handleCropCancelClick(e) {
    e.preventDefault();
    this.setState({ 
      selectedImage: null,
      cropImageSrc: null
    });
    if (this.hiddenInputRef.current) {
      this.hiddenInputRef.current.value = "";
    }
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }

   async makeClientCrop(crop) {
    if (this.cropImageRef && crop.width && crop.height) {
      const cropImageSrc = await this.getCroppedImg(
        this.cropImageRef,
        crop,
        this.state.selectedImageType || 'image/jpeg'
      );
      const cropImageFileObj = this.dataURLtoFile(cropImageSrc, this.state.selectedImageName);
      this.props.selectedImages(cropImageFileObj, this.props.type, this.state.index, cropImageSrc);   
      this.setState({
        cropImageSrc,
        selectedImage: null
      });
      if (this.hiddenInputRef.current) {
        this.hiddenInputRef.current.value = "";
      }
    }
  }

  getCroppedImg(image, crop, imageType) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return canvas.toDataURL(imageType);
  }

  render() {
    const { 
      selectedImage,
      cropImageSrc,
      crop
    } = this.state;

    const {
      imageSrc,
      preventEdit,
      allowDelete,
      deletePhoto,
      index
    } = this.props;

    const bgImage = cropImageSrc || selectedImage || imageSrc;

    const styles = {
      image: {
        backgroundImage: bgImage ? `url(${bgImage})` : "none",
        backgroundColor: bgImage ? "#bc1f2f" : "white",
        backgroundSize: bgImage ? "cover" : "",
        backgroundRepeat: "no-repeat",
      },
    };
    return (
      <>
        {allowDelete && (
          <Button
            style={{ marginTop: 5, marginLeft: "-3em" }}
            floated="right"
            onClick={() => deletePhoto(index)}
            size="tiny"
            negative
            icon={<Icon name="close" />}
          />
        )}
        <div className="image-upload-container" style={styles.image} ref={this.imageUploadContainerRef}>
          <div style={{ display: "block" }} className="image-detail-text">
            <Label
              as="label"
              basic
              htmlFor={this.props.buttonFor}
              className="image-upload"
            >
              {!allowDelete && (
                <Icon
                  name="plus"
                  className="image-upload-button"
                  type="button"
                />
              )}
              <input
                hidden
                type="file"
                ref={this.hiddenInputRef}
                onChange={(e) => this.selectImages(e)}
                disabled={preventEdit}
                id={this.props.buttonFor}
              />
            </Label>
          </div>
        </div>
        { selectedImage && (
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '1rem', textAlign: 'left', marginTop: '1rem', marginBottom: '4px' }}>Crop Image:</div>
            <ReactCrop
              src={selectedImage}
              crop={crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              minHeight={250}
              minWidth={250}
            />
            <Button
              className="profile-buttons"
              onClick={this.handleCropCancelClick}
              content="Cancel"
            />
            <Button
              className="profile-buttons"
              onClick={this.handleCropClick}
              content="Crop"
            />
          </div>
        )}
      </>
    );
  }
}
