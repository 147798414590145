import React, { useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';
import * as Media from "../api/media";
import { fetchVideo } from '../redux/media';
import { useDispatch } from "react-redux";

const Thumbnail = ({ video }) => {
  const series = video?.additionalData?.find(data => data?.name === "series")
  const tile = video?.additionalData?.find(data => data?.name === "tile")
  const [uri, setUri] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchUri() {
      try {
        const image = await Media.getUri(tile.value)
        setUri(image.uri)
      } catch (err) {
        console.log('err:', err)
      }
    }
    fetchUri()
  }, [tile.value])

  const handleClick = async (video) => {
    await dispatch(fetchVideo(video))
  }

  return (
    <div style={{ width: '40%' }}>
      <h4 style={{ marginBottom: 10, marginTop: 30 }}>{series?.value}</h4>
      <Image
        src={uri}
        style={{ cursor: "pointer" }}
        onClick={() => handleClick(video)}
      />
      <h3 style={{ marginTop: 10 }}>{video?.name}</h3>
      <p style={{ marginTop: 10 }}>{video?.description}</p>
    </div>
  )
}

export default Thumbnail;
