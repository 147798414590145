import * as Companies from "../api/companies";
import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = [];

let companies = createSlice({
  name: "companies",
  initialState: initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setCompanies: (_state, action) => {
      return action.payload;
    }
  }
});

export const { reset, setCompanies } = companies.actions;

export const getCompanies = createSelector(
  state => state.companies,
  companies => companies
);


export function fetchCompanies() {  
  return async (dispatch) => {
    try {
      const companies = await Companies.all();
      dispatch(setCompanies(companies));
    } catch (err) {
      console.log(err);
    }
  };
}

export default companies.reducer;
