import React from "react";
import { Route } from "react-router-dom";
import { Grid, Container, Segment } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { getUser } from "../redux/user";
import Navigation from "./Navigation";
import ProfilePage from "../pages/ProfilePage";
import {ReactComponent as AlumniLogo} from "../images/alumniLogo.svg";

const RegisteredRoute = ({ component: Component, ...rest }) => {
  const user = useSelector(getUser);

  return (
    <Route
      {...rest}
      render={(props) =>
        user.unregistered ? (
          <>
            <div>
              <Grid
                stackable
                padded
                className="desktop-navigation welcome-form"
                verticalAlign='bottom'
                >
                <Grid.Row>
                  <Grid.Column width={2}>
                    <div className="alumni-logo-desktop">
                      <AlumniLogo className="alumni-red"alt="alumni"/>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={14}>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            <Container>
              <div className="pages">
                <Container>
                  <Segment vertical>
                    <ProfilePage />
                  </Segment>
                </Container>
              </div>
            </Container>
          </>
        ) : (
          <Navigation>
            <div className="pages">
              <Component {...props} />
            </div>
          </Navigation>
        )
      }
    />
  );
};

export default RegisteredRoute;
