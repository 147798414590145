import * as Jobs from "../api/jobs";
import { createSlice, createSelector } from "@reduxjs/toolkit";
import * as CFHelper from "../util/contentful";
import { getUser } from "./user";

const initialState = [];

let jobs = createSlice({
  name: "jobs",
  initialState: initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setJobs: (_state, action) => {
      return action.payload;
    }
  }
});

export const { reset, setJobs } = jobs.actions;

export const getJobs = createSelector(
  state => state.jobs,
  jobs => jobs
);

export function fetchJobs() {  
  return async (dispatch) => {
    try {
      const jobs = await Jobs.all();
      dispatch(setJobs(jobs));
    } catch (err) {
      console.log(err);
    }
  };
}

export function createJob(job) {  
  return async (dispatch, getState) => {
    CFHelper.addContributorInfo(job, getUser(getState()));
    await Jobs.create(job);
  };
}

export default jobs.reducer;
