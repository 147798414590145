import React from "react";
import { Grid, Container, Header } from "semantic-ui-react";

const HomepageText = () => {
  return (
    <Container className="welcome-section-overlay" style={{ pointerEvents: 'none'}}>
      <div className="welcome-text" style={{ pointerEvents: 'none'}}> 
        <Header
          as="h2" 
          content="Welcome Back"
          inverted
          className="welcome-header-text"
        />
        <p className="welcome-sub-text">
          Whether you have kept in touch with your former colleagues or are looking for old friends, the CAA Alumni Network will help keep you connected to the agency and each other. Discover something new and contribute to our growing network.
        </p>
        <p className="welcome-sub-text-connected">LET'S STAY CONNECTED</p>
      </div>
    </Container>
  );
}

const WelcomeTileImages = ({ profiles, randomProfiles }) => {
  const desktopWallProfiles = randomProfiles(profiles, 24);
  const mobileWallProfiles = randomProfiles(profiles, 16);
  const WelcomeImageTile = (props) => {  
    return (
      <Grid.Column
        className="welcome-image-tiles"
        style={{ backgroundImage: `url(${props.imageURL})` }}
      ></Grid.Column>
    );
  }

  return (
    <div>
      <Grid className="welcome-image-section" container>
        <Grid.Row columns={4} only='mobile' style={{ padding: 0}}>
        {mobileWallProfiles.map(profile => (
            <WelcomeImageTile
            className="image-text-container"
            imageURL={profile.profilePicture.url}
            key={profile.id}
            />
        ))}
        </Grid.Row>
        <Grid.Row columns={6} only='computer tablet' style={{ padding: 0}}>
        {desktopWallProfiles.map(profile => (
            <WelcomeImageTile
            className="image-text-container"
            imageURL={profile.profilePicture.url}
            key={profile.id}
            />
        ))}
        </Grid.Row>
      </Grid>
      <HomepageText />
    </div>
  );
};

export default WelcomeTileImages;
