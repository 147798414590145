export function get() {
  return fetch("/api/whoami", {
    credentials: "include",
    mode: "no-cors",
    headers: {
      accept: "application/json"
    }
  })
    .then(res => (res.ok ? res.json() : Promise.reject()))
    .then(user => {
      user.firstName = user.firstName || user.givenName || user.displayName;
      return user;
    });
}
