import React from "react";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Segment,
  Image,
  Icon
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { getProfiles } from "../redux/profiles";
import { getCompanies } from "../redux/companies";
import { getSocials } from "../redux/socials";
import { getCarouselImages } from "../redux/carouselImages";
import { getMedia } from '../redux/media';
import Newspaper from "../images/newspaper.svg";
import Briefcase from "../images/briefcase.svg";
import Calendar from "../images/calendar.svg";
import ShareBubble from "../images/shareBubble.svg";
import { ReactComponent as Arrow }  from "../images/arrow.svg"
import ScrollAnimation from 'react-animate-on-scroll';
import { CarouselProvider, Slider, Slide, DotGroup, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import PauseOnHover from "../components/PauseOnHover";
import WelcomeTileImages from "../components/WelcomeTileImages";
import Media from "./Media";

const FADE_DURATION = 0.5;
const FADE_DELAY = 1;
const ANIMATION_OFFSET = 1200;

const randomProfiles = (profiles, numberOfImages) => {
  function filterAndRandomize (profiles, diverseFlag) {
    return profiles
            .filter(
              profile => profile.diverse === diverseFlag)
            .map(
              profile => { return { ...profile, sortOrder: Math.random() }; })
            .sort((a, b) => a.sortOrder - b.sortOrder);
  }

  return  filterAndRandomize(profiles, true).slice(0, Math.floor(numberOfImages / 2))
            .concat(
              filterAndRandomize(profiles, false).slice(0, Math.floor(numberOfImages / 2)))
            .sort((a, b) => a.sortOrder - b.sortOrder);
}

const CarouselImage = ({ carouselImage }) => {
  let image;
  if (carouselImage.link) {
    image = <a href={carouselImage.link} target="_blank" rel="noopener noreferrer"><img src={carouselImage.image.url} width="100%" alt="carousel" /></a>;
  } else {
    image = <img src={carouselImage.image.url} width="100%"  alt="carousel" />;
  }
  return (
    <div key={carouselImage.id}>
      {image}
    </div>
  );
};

function HomePageHeader (props) {
  const { profiles, carouselImages } = props;
  const totalSlides = carouselImages.length + 1;
  return (
    <div className="welcome-section">
      <CarouselProvider
        naturalSlideHeight={647}
        naturalSlideWidth={1127}
        totalSlides={totalSlides}
        infinite={true}
        isPlaying={true}
        className="carousel"
      >
        <PauseOnHover>
          <Slider>
            <Slide index={0} key={0}>
              <WelcomeTileImages profiles={profiles} randomProfiles={randomProfiles}/>
            </Slide>
            {carouselImages.map( (carouselImage, index) => (
              <Slide index={index + 1} key={index + 1}>
                <CarouselImage carouselImage={carouselImage} />
              </Slide>
            ))}
          </Slider>
        </PauseOnHover>
        <DotGroup />
        <ButtonBack><Arrow className="arrow" /></ButtonBack>
        <ButtonNext><Arrow className="arrow"  style={{transform: "scaleX(-1)"}}/></ButtonNext>
      </CarouselProvider>
    </div>
  );
}

export class ImageTile extends React.Component {
  state = { displayed: false, textOverflow: false };
  hideTextDetail = () => this.setState({ displayed: false });
  showTextDetail = () => this.setState({ displayed: true });
  constructor(props) {
    super(props)
    this.getTextLength = React.createRef();
  }
  componentDidMount() {
    const textLength = get(this.getTextLength, "current.textContent.length", 0);
    const baseSize = 14;
    if (textLength >= baseSize) {
      this.setState({textOverflow: true})
    }
  }
  render() {
    const { displayed } = this.state;
    const { profileId, alumniDetail, imageURL, hoverEffects } = this.props;
    return (
      <Link to={`/profile/${profileId}`}>
        <div
          className="alumni-image-tile"
          style={{
            backgroundImage: displayed && hoverEffects
              ? "none"
              : `url(${imageURL})`,
            backgroundColor: displayed && hoverEffects ? "#bc1f2f" : "#f2f2f2"
          }}
          onMouseLeave={this.hideTextDetail}
          onMouseEnter={this.showTextDetail}
        >
          <div
            style={{ display: displayed && hoverEffects ? "block" : "none" }}
            className="image-detail-text"
          >
            <h3 ref={this.getTextLength} className={this.state.textOverflow ? "decreaseFontSize" : ""}>
              {get(alumniDetail, "firstName", "")}
              <br />
              {get(alumniDetail, "lastName", "")}
            </h3>
            <p>{get(alumniDetail, "currentJobTitle", "")}</p>
            <p>{get(alumniDetail, "currentLocation", "")}</p>
          </div>
        </div>
      </Link>
    );
  }
}

class Home extends React.Component {
  render() {
    const { profiles, companies, socials, carouselImages, media } = this.props;
    const multiDiverse = randomProfiles(profiles, 10);
    return (
      <>
        <HomePageHeader profiles={profiles} carouselImages={carouselImages} />
        <Container className="home-page">
          <Segment vertical>
            <ScrollAnimation
              animateIn="fadeIn"
              animateOnce={true}
              duration={FADE_DURATION}
              animatePreScroll={true}
              offset={ANIMATION_OFFSET}
              >
              <Header as="h3">THIS IS YOUR NETWORK</Header>
              <p className="home-page-subheaders">
                CAA alumni continue the tradition of excellence and are located
                all over the world. You can find fellow alumni at the top
                entertainment, media companies, and beyond.
              </p>
            </ScrollAnimation>
            <Grid doubling centered className="your-network-section">
              <Grid.Row columns={5}>
                {companies.filter((company) => company.featured).map((company, index) => (
                  <Grid.Column className="network-images" key={index}>
                    <ScrollAnimation
                      animateIn="fadeIn"
                      animateOnce={true}
                      duration={FADE_DURATION}
                      offset={ANIMATION_OFFSET}
                      >
                      <a
                        href={get(company, "url", "#")}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <div
                          className="network-image-tiles"
                          style={{
                            backgroundImage: `url(${company.logo.url})`
                          }}
                        ></div>
                      </a>
                    </ScrollAnimation>
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
            <Divider section />
          </Segment>
        </Container>

        <Container className="home-page">
            <Segment vertical>
              <ScrollAnimation
                animateIn='fadeIn'
                animateOnce={true}
                duration={FADE_DURATION}
                offset={ANIMATION_OFFSET}
                >
                <Header as="h3">OLD FRIENDS, NEW ALUMNI</Header>
                <p className="home-page-subheaders">
                  Meet the newest alums who have joined our network.
                </p>
              </ScrollAnimation>
              <Grid doubling centered className="your-alumni-section">
                <Grid.Row columns={5}>
                  {multiDiverse.map((profile, index) => (
                    <Grid.Column className="network-images" key={profile.id}>
                      <ScrollAnimation
                        animateIn="fadeIn"
                        animateOnce={true}
                        delay={index * FADE_DELAY}
                        duration={FADE_DURATION}
                        offset={ANIMATION_OFFSET}
                      >
                        <ImageTile
                          imageURL={profile.profilePicture.url}
                          alumniDetail={profile}
                          profileId={profile.id}
                          hoverEffects={true}
                        />
                      </ScrollAnimation>
                    </Grid.Column>
                  ))}
                </Grid.Row>
              </Grid>
              <ScrollAnimation
                animateIn='fadeIn'
                animateOnce={true}
                duration={FADE_DURATION}
                delay={FADE_DELAY}
                offset={ANIMATION_OFFSET}
                >
                <Button
                  className="home-page-buttons"
                  as="a"
                  size="medium"
                  href="/network"
                >
                  VIEW YOUR NETWORK
                </Button>
              </ScrollAnimation>
              <Divider section />
            </Segment>
        </Container>

        {media.length ? (
          <Container className="home-page">
            <Segment vertical>
              <ScrollAnimation
                animateIn='fadeIn'
                animateOnce={true}
                duration={FADE_DURATION}
                offset={ANIMATION_OFFSET}
              >
                <Media />
              </ScrollAnimation>
            </Segment>
          </Container>
        ) : null}

        <Container className="home-page">
          <Segment className="stay-in-the-know-section" vertical>
            <ScrollAnimation
              animateIn='fadeIn'
              animateOnce={true}
              duration={FADE_DURATION}
              offset={ANIMATION_OFFSET}
            >
              <Header as="h3">STAY IN THE KNOW</Header>
              <p className="home-page-subheaders">
                Follow our social accounts to stay in the discussion.
              </p>
            </ScrollAnimation>
              <Grid doubling centered columns={4} textAlign="left" className="socials">
                {socials.map(social => (
                  <Grid.Column key={social.id}>
                    <div className="socials-container">
                      <ScrollAnimation
                      animateIn='fadeIn'
                      animateOnce={true}
                      duration={FADE_DURATION}
                      offset={ANIMATION_OFFSET}
                      >
                      <a href={social.url} target="_blank" rel="noopener noreferrer">
                        <div className="social-links">
                          <Icon name={social.network}/>
                          {social.logo.url !== "#" && (
                          <Image
                            src={get(social, "logo.url")}
                            className="social-logo"
                            size='small'
                          />)
                        }
                        {social.logo.url === "#" && (
                          <p className="social-name">
                            {social.name}
                          </p>
                          )
                        }
                        </div>
                      </a>
                      <div className="socials-description">
                        {documentToReactComponents(social.description)}
                      </div>
                      <div className="social-posts-image">
                        <Image
                          src={get(social, "samplePostsImage.fields.file.url")}
                          href={social.url}
                          target="_blank"
                          fluid
                        />
                      </div>
                      </ScrollAnimation>
                    </div>
                  </Grid.Column>
                  )
                )}
            </Grid>
            <Divider section />
          </Segment>
        </Container>

        <Container className="home-page">
          <Segment className="share-celebrate" vertical>
            <ScrollAnimation
              animateIn='fadeIn'
              animateOnce={true}
              duration={FADE_DURATION}
              offset={ANIMATION_OFFSET}
            >
              <Header as="h3">SHARE AND CELEBRATE</Header>
              <p className="home-page-subheaders">
                Make use of the network and contribute by sharing job
                opportunities, upcoming events, and most importantly, keep us
                informed as your career grows.
              </p>
            </ScrollAnimation>
              <Grid doubling centered columns={4} textAlign="left" className="share-celebrate-info">
                <Grid.Column>
                  <ScrollAnimation
                  animateIn='fadeIn'
                  animateOnce={true}
                  duration={FADE_DURATION}
                  offset={ANIMATION_OFFSET}
                  >
                    <Image src={Newspaper} className="share-and-celebrate-icons"/>
                    <h3>STAY IN THE KNOW</h3>
                    <p>Get your latest CAA and industry news here.</p>
                    <Link to="/news">Visit News &gt;</Link>
                  </ScrollAnimation>

                </Grid.Column>
                <Grid.Column>
                  <ScrollAnimation
                  animateIn='fadeIn'
                  animateOnce={true}
                  delay={FADE_DELAY}
                  duration={FADE_DURATION}
                  offset={ANIMATION_OFFSET}
                  >
                    <Image src={Calendar} className="share-and-celebrate-icons"/>
                    <h3>EVENTS</h3>
                    <p>
                      Looking for a CAA or alumni event in your city? Find out
                      information about the next event here.
                    </p>
                    <Link to="/events">Visit Events &gt;</Link>
                  </ScrollAnimation>

                </Grid.Column>
                <Grid.Column>
                  <ScrollAnimation
                    animateIn='fadeIn'
                    animateOnce={true}
                    delay={FADE_DELAY}
                    duration={FADE_DURATION}
                    offset={ANIMATION_OFFSET}
                  >
                    <Image src={Briefcase} className="share-and-celebrate-icons"/>
                    <h3>OPPORTUNITIES</h3>
                    <p>
                      Use the power of the CAA network to share and connect to
                      incredible job opportunities, referrals, and advice.
                    </p>
                    <Link to="/careers">Visit Opportunities ></Link>
                  </ScrollAnimation>

                </Grid.Column>
                <Grid.Column>
                  <ScrollAnimation
                    animateIn='fadeIn'
                    animateOnce={true}
                    delay={FADE_DELAY}
                    duration={FADE_DURATION}
                    offset={ANIMATION_OFFSET}
                  >
                    <Image src={ShareBubble} className="share-and-celebrate-icons"/>
                    <h3>SHARE</h3>
                    <p>
                      We want to know all about your professional accomplishments.
                      Upload information and share it with our network.
                    </p>
                    <Link to="/share">Visit Share &gt;</Link>
                  </ScrollAnimation>
                </Grid.Column>
              </Grid>
          </Segment>
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    profiles: getProfiles(state),
    companies: getCompanies(state),
    socials: getSocials(state),
    carouselImages: getCarouselImages(state),
    media: getMedia(state)
  };
}

export default connect(mapStateToProps)(Home);
