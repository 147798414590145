import * as News from "../api/news";
import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = [];

let news = createSlice({
  name: "news",
  initialState: initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setNews: (_state, action) => {
      return action.payload;
    }
  }
});

export const { reset, setNews } = news.actions;

export const getNews = createSelector(
  state => state.news,
  news => news
);


export function fetchNews() {  
  return async (dispatch) => {
    try {
      const news = await News.all();
      dispatch(setNews(news));
    } catch (err) {
      console.log(err);
    }
  };
}

export default news.reducer;
