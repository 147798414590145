import React, { useState, useEffect } from "react";
import { Container, Grid, Segment, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import { filter, get, each, uniqBy, sortBy, map, random, } from "lodash";
import { getProfiles } from "../redux/profiles";
import SearchBar from "../components/SearchBar";
import YearFilter from "../components/YearFilter";
import DropdownFilter from "../components/DropdownFilter";
import Delayed from "../components/Delayed";
import { ImageTile} from "./Home";
import ScrollAnimation from "react-animate-on-scroll";

function SearchNetworkSection (props) {
  const { profiles } = props;
  const [filteredProfiles, setFilteredProfiles] = useState(profiles);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  function buildYears () {
    let years = [];
    each(filteredProfiles, (p) => {
      if(p.startYear && p.endYear) {
        for(let y = p.startYear; y <= p.endYear; y++) {
          years.push({key:y.toString(), text:y.toString(), value:y})
        }
      }
    });
    return uniqBy(years, (y) => y.value).sort((a, b) => b.value - a.value);
  }
  let companyValues = sortBy(filteredProfiles.filter(p => get(p, "company.name")).map(profile => ({key:profile.company.id, text:profile.company.name.toUpperCase(), value:profile.company.name.toUpperCase()})), 'value');
  let cityValues = sortBy(filteredProfiles.filter(p => p.city).map(profile => ({key:profile.id, text:profile.city.toUpperCase(), value:profile.city.toUpperCase()})), 'value');
  let yearValues = buildYears();

  useEffect(() => {
    let filteredItems = profiles;

    if (selectedCompany || selectedYear || selectedCity) {
      filteredItems = filter(profiles, employee => {
        let matches = true;
        let company = get(employee, "company.name", "").toUpperCase();
        let city = get(employee, "city", "").toUpperCase();
        if(selectedCompany) {
          matches = matches && (company === (selectedCompany || "").toUpperCase());
        }
        if(selectedCity) {
          matches = matches && (city === (selectedCity || "").toUpperCase());
        }
        if(selectedYear) {
          matches = matches && (selectedYear >= employee.startYear && selectedYear <= employee.endYear);
        }
        return matches;
      });
      setFilteredProfiles(sortBy(filteredItems, ['lastName', 'firstName']));
    }
    else {
      setFilteredProfiles(sortBy(map(filteredItems, (item) => {
        return {...item, sortOrder: random(0, filteredItems.length+1)};
      }), ['sortOrder']));
    }
  }, [profiles, selectedYear, selectedCompany, selectedCity]);


  return (
    <Container className="network-page">
      <div className="network-search-segment">
        <Grid
          doubling
        >
          <Grid.Column computer={6} tablet={8} mobile={16}>
            <SearchBar
              name="network"
              source={profiles.map(
                item =>
                  (item = {
                    title: get(item, "firstName", "").concat(
                      " ",
                      get(item, "lastName", "")
                    ),
                    description: get(item, "currentLocation", ""),
                    image: item.profilePicture,
                    id: item.id
                  })
              )}
              history={props.history}
              placeholder="SEARCH NETWORK"
            />
          </Grid.Column>
          <Grid.Column computer={3} tablet={8} mobile={16} className="dropdown-min-width" >
            <DropdownFilter
              source={profiles}
              className="company-dropdown"
              name="companyFilter"
              placeholder="Select Company"
              dropDownOptions={companyValues}
              filteredProfiles={filteredProfiles}
              setSelected={(value => {setSelectedCompany(value)})}
          />
        </Grid.Column>
        <Grid.Column computer={3} tablet={8} mobile={16}className="dropdown-min-width">
          <DropdownFilter
            source={profiles}
            className="city-dropdown"
            name="cityFilter"
            placeholder="Select City"
            dropDownOptions={cityValues}
            filteredProfiles={filteredProfiles}
            setSelected={(value => {setSelectedCity(value)})}
        />
        </Grid.Column>
        <Grid.Column computer={3} tablet={8} mobile={16}>
          <YearFilter
            source={profiles}
            filteredProfiles={filteredProfiles}
            dropDownOptions={yearValues}
            setSelectedYear={(value => {setSelectedYear(value)})}
            className="year-filter"
          />
          </Grid.Column>
        </Grid>
        <Segment basic className="network-images-segment">
          <Grid
            className="network-images-section"
            doubling
            columns={6}
          >
            {!(Array.isArray(filteredProfiles) && filteredProfiles.length) &&
              (
                <Delayed wait={1000}>
                  <div className="pages">
                    <Container text style={{ marginTop: "7em" }}>
                      <Header as="h1">No Results for selected criteria.</Header>
                    </Container>
                  </div>
                </Delayed>
              )
            }
            {filteredProfiles.map((profile, index) => (
                <Grid.Column className={`network-images  ${index < 6 ? "initiallyVisible": ""}`} key={profile.id}>
                  <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={0.5} offset={1200}>
                    <div className="network-tile">
                      <ImageTile
                        imageURL={profile.profilePicture.url}
                        alumniDetail={profile}
                        profileId={profile.id}
                        hoverEffects={false}
                      />
                      <div className="image-footer-text" >
                        <h3
                          className="first-name"
                          style={{fontSize: (profile.firstName.length + profile.lastName.length) > 14 ? '16px' : '1.4em'}}
                        >{profile.firstName}  </h3>
                        <h3
                          className="last-name"
                          style={{fontSize: (profile.firstName.length + profile.lastName.length) > 14 ? '16px' : '1.4em'}}
                        >{profile.lastName} </h3>
                        <p className="city">
                        {
                          profile.currentCompany && (
                            <span>
                              {profile.currentCompany} <br/>
                            </span>
                          )
                        }
                        {profile.currentLocation}
                        </p>

                      </div>
                    </div>
                  </ScrollAnimation>
                </Grid.Column>
              ))}
          </Grid>
        </Segment>
      </div>
    </Container>
  );
}

class Network extends React.Component {
  render() {
    const { profiles, history} = this.props;
    return (
      <SearchNetworkSection
        profiles={profiles}
        history={history}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    profiles: getProfiles(state)
  };
}

export default connect(mapStateToProps)(Network);
