import React, { useState } from "react";
import { Table, Button } from "semantic-ui-react";
import * as UserHelpers from "../../util/user";
import * as Profiles from "../../api/profiles";
import ApprovalModal from "./ApprovalModal";
import EditModal from "./EditModal";
import { InviteModal } from "./InviteModal";

export default function UserProfileRow({
  userProfile,
  onAction,
  resendInvite,
  loading
}) {
  const [rejecting, setRejecting] = useState(false);
  const {
      status,
      firstName,
      lastName,
      email,
      partyId,
      startYear,
      endYear,
      officeLocationWorkedIn
  } = userProfile;
  async function reject() {
    setRejecting(true);
    const rawProfile = await Profiles.getByProfileId(userProfile.id);
    await UserHelpers.reject(rawProfile);
    onAction();
    setRejecting(false);
  }

  return (
    <Table.Row disabled={loading}>
      <Table.Cell>{status}</Table.Cell>
      <Table.Cell>{firstName}</Table.Cell>
      <Table.Cell>{lastName}</Table.Cell>
      <Table.Cell>{email}</Table.Cell>
      <Table.Cell>{startYear}</Table.Cell>
      <Table.Cell>{endYear}</Table.Cell>
      <Table.Cell>{officeLocationWorkedIn}</Table.Cell>
      <Table.Cell>
        <EditModal onEdit={onAction} userProfile={userProfile} disabled={loading} />
      </Table.Cell>
      <Table.Cell>
        {status === "requested" ? (
          <ApprovalModal onApproved={onAction} userProfile={userProfile} disabled={loading} />
        ) : null}
        {status === "invited" ? (
          <InviteModal onInvite={resendInvite} isResendingInvite={true} partyId={partyId} name={`${firstName} ${lastName}`} alumniEmail={email} />
        ) : null}
      </Table.Cell>
      <Table.Cell>
        {status === "requested" ? (
          <Button
            negative
            onClick={reject}
            content="Reject"
            loading={rejecting}
            disabled={loading}
          />
        ) : null}
      </Table.Cell>
    </Table.Row>
  );
}
