import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Grid, Icon, Segment, Header, Button, Image } from "semantic-ui-react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { getProfiles } from "../redux/profiles";
import { getProfileId } from "../redux/user";
import { get, find, startCase, map } from "lodash";
import BackButton from "../components/BackButton";
import Delayed from "../components/Delayed";
import { ReactComponent as EmailIcon } from "../images/email.svg";
import ScrollAnimation from 'react-animate-on-scroll';
import { SRLWrapper } from "simple-react-lightbox";

function SocialIcon (props) {
  const { url, network } = props;
  return (
    <>
      {url && (
        <a href={url} target="_blank" rel="noopener noreferrer">{network === "envelope outline" ? <div className="email-icon"><EmailIcon name={network}/></div> : <Icon name={network}/>}</a>
      )}
    </>
  );
}

function TextSection (props) {
  const { title, text } = props;
  return (
    <>
      {text && (
        <>
          <h3>{title}</h3>
          <p className="text-wrap">{text}</p>
        </>
      )}
    </>
  );
}

function Profile (props) {
  const { match: { params } } = props;
  const profileId = get(params, "id");
  const { profiles } = props;
  const profile = profiles.find((p) => p.id === profileId);
  const [height, setHeight] = useState(0);
  const getElementHeight = useRef({current: {clientHeight: 0}});
  const elementHeight = height ? height + 20 : 156; //add margin 20 to div height
  const lightboxOptions = {
    buttons: {
      showDownloadButton: false
    },
    thumbnails: {
      showThumbnails: false
    }
  }
  let facebook, twitter, instagram, linkedin, podcasts, allPhotos;
  let isOwnProfile = false;

  useEffect(() => {
    setHeight(getElementHeight.current.clientHeight);
  }, []);

  if(profile) {
    facebook = find(profile.socialLinks, (url) => url.indexOf("facebook") > -1);
    twitter = find(profile.socialLinks, (url) => url.indexOf("twitter") > -1);
    instagram = find(profile.socialLinks, (url) => url.indexOf("instagram") > -1);
    linkedin = find(profile.socialLinks, (url) => url.indexOf("linkedin") > -1);
    podcasts =  map(profile.podcasts, name => startCase(name)).join(', ');
    isOwnProfile = profile.id === props.userProfileId;
    allPhotos = profile.additionalPhotos.concat(profile.petPhoto);
  }

  return (
    <>
      {!profile && (
        <Delayed wait={1000}>
          <div className="pages">
            <Container className="no-profile-page">
              <Segment vertical>
                {isOwnProfile && (
                  <>
                    <Header as="h3">We are having trouble finding your profile</Header>
                    <div className="no-profile-page-subheaders">
                      <p>This page is not available to employees.</p>
                      <p>If you are not an employee and still see this message.</p>
                      <p>Please contact CAA</p>
                    </div>
                  </>
                )}
                {!isOwnProfile && (
                  <Header as="h3">We are having trouble finding the requested profile</Header>
                )}
              </Segment>
            </Container>
          </div>
        </Delayed>
      )}
      {!!profile && (
        <Container className="network-page">
          <Segment vertical className="network-results-segment">
            <Grid
              stackable
              textAlign="left"
              verticalAlign="middle"
            >
              <Grid.Column width={16}>
                <Link to="/network">
                  <BackButton
                    label="BACK TO NETWORK"
                  />
                </Link>
              </Grid.Column>
            </Grid>
            <Grid
              className="network-image-detail-section"
              stackable
            >
              <Grid.Column computer={4} tablet={16} mobile={16}className="search-result-image add-padding">
                <ScrollAnimation
                  animateIn="fadeIn"
                  duration={0.5}
                  animateOnce={true}
                  offset={1200}
                >
                  <div
                    className="search-result-image-tile"
                    style={{
                      backgroundImage: `url(${profile.profilePicture.url})`
                    }}
                  ></div>
                </ScrollAnimation>
              </Grid.Column>
              <Grid.Column computer={6} tablet={16} mobile={16}>
                <ScrollAnimation
                  animateIn="fadeIn"
                  duration={0.5} delay={1}
                  animateOnce={true}
                  offset={1200}
                >
                  <div className="single-image-detail-text" ref={getElementHeight}>
                    <h1 className="first-name">{profile.firstName}</h1>
                    <h1 className="last-name">{profile.lastName}</h1>
                    <p>{profile.currentCompany}
                      <br/>
                    {profile.currentLocation}
                    </p>
                  </div>
                  <div className="section-divider">
                    <hr></hr>
                  </div>
                  <div className="image-bio-text">
                    <h3>BIO</h3>
                    <div>{documentToReactComponents(get(profile, 'bio', ''))}</div>
                  </div>
                  <div className="years-text">
                    <h4>Years worked at CAA</h4>
                    <div>{`${get(profile, 'startYear', '')} - ${get(profile, 'endYear', '')}`}</div>
                  </div>
                </ScrollAnimation>
              </Grid.Column>
              <Grid.Column computer={6} tablet={16} mobile={16}>
                <ScrollAnimation
                  animateIn="fadeIn"
                  duration={0.5}
                  delay={1}
                  animateOnce={true}
                  offset={1200}
                  >
                  <div className="edit-profile-button-container" style={{height: elementHeight}}>
                    {isOwnProfile && (
                      <Button
                        className="edit-profile-button"
                        as="a"
                        size="medium"
                        href="/editProfile"
                        rel="noopener noreferrer"
                      >
                        Edit Profile
                      </Button>
                    )}
                  </div>
                  <div className="social-icons-section">
                    <div className="social-icon-item">
                      {profile.email && (
                        <Icon.Group>
                          <SocialIcon url={`mailto:${profile.email}`} network="envelope outline" />
                          <SocialIcon url={twitter} network="twitter" />
                          <SocialIcon url={linkedin} network="linkedin" />
                          <SocialIcon url={instagram} network="instagram" />
                          <SocialIcon url={facebook} network="facebook f" />
                        </Icon.Group>
                          )}
                      </div>
                    </div>
                  <div className="section-divider">
                    <hr></hr>
                  </div>
                  <div className="social-text-section questions">
                    <TextSection title="Where are you traveling to next?" text={profile.travelingTo} />
                    <TextSection title="What are you reading now?" text={profile.readingNow} />
                    <TextSection title="What are you proud of?" text={profile.proudOf} />
                    <TextSection title="What podcasts are you listening to?" text={podcasts} />
                    {allPhotos && allPhotos.length > 0 && (
                      <div className="favorite-beings-section">
                        <div>
                          <h3>Favorite Beings</h3>
                        </div>
                        <div className="photos">

                          <SRLWrapper options={lightboxOptions}>
                            <Grid
                              columns={2}
                              stackable
                              >
                            {allPhotos.map((photo, i) => (
                              <Grid.Column key={i}>
                                  <Image src={photo.url} className="additional-photos" alt={photo.caption}/>
                                  <div className="image-caption">{photo.caption}</div>
                              </Grid.Column>
                            ))}
                            </Grid>
                          </SRLWrapper>
                        </div>
                      </div>
                    )}
                  </div>
                </ScrollAnimation>
              </Grid.Column>
            </Grid>
          </Segment>
        </Container>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    profiles: getProfiles(state),
    userProfileId: getProfileId(state)
  };
}

export default connect(mapStateToProps)(Profile);
