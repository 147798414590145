import React from "react";
import { Table, Pagination } from "semantic-ui-react";
import UserProfileRow from "./UserProfileRow";
import StatusDropdown from "./StatusDropdown";

export default class UserProfileGrid extends React.Component {
  render() {
    const { profiles, currentPagination, totalPagination, setCurrentPagination, handleDropDownChange, isTyping } = this.props;
    return (
      <Table celled striped attached>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <StatusDropdown
                isTyping={isTyping}
                handleDropDownChange={handleDropDownChange}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Start Year</Table.HeaderCell>
            <Table.HeaderCell>End Year</Table.HeaderCell>
            <Table.HeaderCell>CAA Office Location</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {profiles.map(userProfile => (
            <UserProfileRow
              userProfile={userProfile}
              key={userProfile.id}
              {...this.props}
            />
          ))}
          <Table.Row>
            <Table.Cell textAlign="right" colSpan={10}>
              <Pagination
                activePage={currentPagination}
                totalPages={totalPagination}
                onPageChange={(e, { activePage }) => {
                  setCurrentPagination(activePage);
                }}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}
