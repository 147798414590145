import React, { useEffect } from "react";
import { Container, Segment, Header } from "semantic-ui-react";

export default function TermsOfUse() {
  useEffect(() => {
    //interval is needed to check when OneTrust api become available via Window
    let interval = setInterval(()=>{
      if(window.OneTrust){
        window.OneTrust.NoticeApi.Initialized.then(function() {
          window.OneTrust.NoticeApi.LoadNotices(["https://privacyportal-cdn.onetrust.com/722df920-f42e-4d91-b843-6306f9a6a5a0/privacy-notices/e3a4fcc2-10a4-4ba3-8b93-c1c791f6845b.json"]);
        });
        clearInterval(interval);
      }
    }, 200);
  }, [])

  return (
    <Container className="terms-of-use-page">
      <Segment vertical>
        <Header as="h3">Website Terms of Use</Header>
        <div className="terms-of-use-page-subheaders">
          <div
            id="otnotice-e3a4fcc2-10a4-4ba3-8b93-c1c791f6845b"
            class="otnotice"
          ></div>
        </div>
      </Segment>
    </Container>
  );
}
