import { useState, useEffect } from "react";
import axios from "axios";

const useTypeahead = (searchText) => {
  const [searchResults, setSearchResults] = useState([]);
  const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let shouldCancel = true;

    const debounce = setTimeout(async () => {
      try {
        if (searchText) {
          setLoading(true);

          const { data } = await axios.get(
            `/api/locationTypeahead?address=${searchText}`,
            {
              cancelToken: cancelToken.token,
            }
          );

          const citites = data.predictions.map((city) => ({
            id: city.id,
            title: city.description,
          }));

          setSearchResults(citites);
          setLoading(false);
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          setCancelToken(axios.CancelToken.source());
        } else {
          shouldCancel = false;
          setLoading(false);
        }
      }
    }, 500);

    return () => {
      if (shouldCancel) {
        cancelToken.cancel();
      }
      clearTimeout(debounce);
    };
  }, [searchText, cancelToken]);

  return { loading, searchResults };
};

export default useTypeahead;
