import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import store from "./redux/store";
import { fetchUser, getUser } from "./redux/user";
import axios from "axios";
import Cookies from "universal-cookie";
import base64url from "base64-url";
import "semantic-ui-css/semantic.min.css";
import "./styles/index.css";
import App from "./components/App";
import Landing from "./pages/Landing";
import { initInsights } from "./analytics";

const cookies = new Cookies();

const rootElement = document.getElementById("root");
const xsrfCookieName = "crumb";
const xsrfHeaderName = "x-csrf-token";
const deleteHeader = {
  "Content-Type": "application/json;charset=utf-8"
};

axios.defaults.xsrfCookieName = xsrfCookieName;
axios.defaults.xsrfHeaderName = xsrfHeaderName;
axios.defaults.headers.delete = deleteHeader;

Promise.all([
  axios.get("/healthcheck"),
  store.dispatch(fetchUser())
])
  .then(([ hcResponse ]) => {
    const user = getUser(store.getState());

    const env = hcResponse.data.e === "production" ? "production" : "default";
    const historyForRouter = initInsights(user.partyId, env);

    if (
      user.scope.includes("CAA\\SEC-ALUMNI-EDITORS") ||
      user.scope.includes("CAA\\SEC-ALUMNI-VIEWERS") ||
      user.scope.includes("EXT\\ALUMNI")
    ) {
      ReactDOM.render(<App history={historyForRouter} />, rootElement);
    } else {
      if (window.location.pathname === "/admin") {
        cookies.set("caaRequestPath64", base64url.encode("/admin"), { path: "/" })
        window.location.href = `${window.location.origin}/login?employee=true`
      } else {
        ReactDOM.render(<Landing />, rootElement);
      }
    }
  })
  .catch(() => {
    if (window.location.pathname === "/admin") {
      cookies.set("caaRequestPath64", base64url.encode("/admin"), { path: "/" })
      window.location.href = `${window.location.origin}/login?employee=true`
    } else {
      ReactDOM.render(<Landing />, rootElement);
    }
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
