import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import { validate } from "email-validator";

export const validateEmail = validate;

function EmailInput({ onChange, value, required, readOnly }) {
  const [email, setEmail] = useState(value || "");
  const [emailDirty, setEmailDirty] = useState(false);
  const valid = validate(email);
  const error =
    !valid && emailDirty
      ? {
          content: "Please enter a valid email address",
          pointing: "above"
        }
      : null;

  function handleEmailChange(_, { value }) {
    setEmail(value);
    onChange(value, validate(value));
  }

  return (
    <Form.Input
      id="emailAddress"
      label="Email Address"
      readOnly={readOnly ?? false}
      placeholder="name@example.com"
      onChange={handleEmailChange}
      onBlur={() => setEmailDirty(true)}
      value={email}
      error={error}
      required={required}
    />
  );
}

export default EmailInput;
