import * as Accolades from "../api/accolades";
import * as CFHelper from "../util/contentful";
import { getUser } from "./user";

export function createAccolade(accolade) {  
  return async (dispatch, getState) => {
    CFHelper.addContributorInfo(accolade, getUser(getState()));
    await Accolades.create(accolade);
  };
}
