import React, { useState, useEffect } from "react";
import { Button, Container, Grid, Segment, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import get from "lodash/get";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { getEvents } from "../redux/events";
import SearchBar from "../components/SearchBar";
import Delayed from "../components/Delayed";
import DropdownFilter from "../components/DropdownFilter";
import ScrollAnimation from "react-animate-on-scroll";

const DATE_OPTIONS = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

function convertEventsForSearch (events) {
  return events.map(
    item =>
      (item = {
        title: get(item, "title", ""),
        description: new Intl.DateTimeFormat("en-US", DATE_OPTIONS).format(
          new Date(get(item, "when", ""))
        ),
        image: item.image,
        id: get(item, "id", "")
      })
  );
}

function EventImageTile (props) {
  return (
      <div
        className="network-image-tiles"
        style={{ backgroundImage: `url(${props.imageURL})` }}
      ></div>
  );
}

function EventTile (props) {
  const { event, tileIndex } = props;
  return (
    <Grid.Column className="network-images">
      
        <div className="image-text-container">
          <ScrollAnimation 
            key={event.id} 
            animateIn="fadeIn" 
            delay={tileIndex * 100} 
            duration={3}
            animateOnce={true}
            offset={1200}
          >
            <div className="image-container">
              <Link to={`/event/${get(event, "id", "1")}`}>
                <EventImageTile
                  imageURL={event.image.url}
                  index={tileIndex}
                />
              </Link>
            </div>
            <div className="event-detail-text">
              <div className="heading-text">
                <h3>{get(event, "title", "")}</h3>
              </div>
              <p className="event-date">
                {new Intl.DateTimeFormat("en-US", {year: "numeric", month:"long", day: "numeric"}).format(
                  new Date(get(event, "when", ""))
                )}
                <br/>
                {new Intl.DateTimeFormat("en-US", {hour: "numeric", minute: "numeric"}).format(
                  new Date(get(event, "when", ""))
                )}
              </p>
              <p>{get(event, "location", "")}</p>
            </div>     
            <div className="events-button event-page-button-container">
              <Link to={`/event/${get(event, "id", "1")}`}>
                <Button>Learn More</Button>
              </Link>
            </div>
          </ScrollAnimation>
        </div>

    </Grid.Column>
  );
}

function TileCalendarSwitcher(props) {
  return (
      <div 
        className="tile-calendar-toggle-container"
        style={{ float: !props.isTileView ? "none": "right", textAlign: !props.isTileView ? "right" : "none" }}
      >
        <Button
          className="tile-toggle"
          onClick={() => props.onClick({ isTileView:true })}
          style={{ backgroundColor: props.isTileView ? "#353839": "#ffffff", color: props.isTileView ? "#ffffff" : "" }}
          attached="left"
        >
          Tiles
        </Button>
        <Button
          className="calendar-toggle" 
          attached="right"
          onClick={() => props.onClick({ isTileView:false, view: "calendar" })}
          style={{ backgroundColor: !props.isTileView ? "#353839": "#ffffff", color: !props.isTileView ? "#ffffff" : "" }}
        >
          Calendar
        </Button>
      </div>
  )
}

function TileView (props) {
  const { events } = props;
  const [filteredEvents, setFilteredEvents] = useState(events);
  useEffect(() => {
    setFilteredEvents(events);
  }, [events]);
  const dropdownValues = events.filter(e => e.city).map(event => ({key:event.id, text:event.city.toUpperCase(), value:event.city.toUpperCase()}));

  return (
      <div>
        <Grid doubling stackable columns="equal">
          <Grid.Column width={8}>
            <SearchBar
              name="events"
              source={convertEventsForSearch(events)}
              placeholder="SEARCH EVENTS"
              history={props.history}
            />
          </Grid.Column>
          <Grid.Column>
            <Grid stackable>
              <Grid.Column width={7}>
                <DropdownFilter
                setFilter={(filteredItems) => {setFilteredEvents(filteredItems)}}
                source={events}
                className="events-dropdown"
                name="cityFilter"
                placeholder="Select A City"
                dropDownOptions={dropdownValues}
                filterBy="city"
              />
              </Grid.Column>
              <Grid.Column width={9}>
                <TileCalendarSwitcher onClick={props.onClick} isTileView={props.isTileView} />
              </Grid.Column>
            </Grid>
          </Grid.Column>
          
        </Grid>
        <Grid
          doubling
          stackable
          columns={4}
          stretched
          className="events-images-section"
        >
          {!(Array.isArray(filteredEvents) && filteredEvents.length) &&
            (
              <Delayed wait={1000}>
                <div className="pages">
                  <Container text style={{ marginTop: "7em" }}>
                    <Header as="h1">No Results for selected city.</Header>
                  </Container>
                </div>
              </Delayed>
            )
          }
          {filteredEvents.map((event,i) => (
            <EventTile event={event} key={event.id} tileIndex={i}/>
          ))}
        </Grid>
      </div>
  );
}


export class CalendarView extends React.Component {
  
  handleOpen = (event) => this.props.history.push({ pathname: `/event/${event.id}`})

  render() {
    const { events } = this.props;
    const localizer = momentLocalizer(moment);
    const mappedEvents = events.map(event => {
      return {
        id: event.id,
        title: get(event, 'title', ''),
        allDay: false,
        start: get(event, 'when', ''),
        end: get(event, 'when', ''),
        location: get(event, 'location', ''),
        image: event.image.url
      }
    });
    return (
      <div>
          <TileCalendarSwitcher 
            onClick={this.props.onClick} 
            isTileView={this.props.isTileView}
          />
        <Calendar
          views={{ month: true }}
          localizer={localizer}
          events={mappedEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 700, paddingTop: "30px" }}
          onSelectEvent={this.handleOpen}
        />
      </div>
    )
  }
}

class Events extends React.Component {
  state = { isTileView: true };

  onClick(stateChanges) {
    this.setState(stateChanges);
  }

  viewSelector() {
    const { isTileView } = this.state;
    const { events, history } = this.props;
    let sortedEvents = events.slice().sort((a, b) => {
      if(a.when < b.when) { return -1; }
      else if(a.when > b.when) { return 1; }
      return 0;
    })
    if (isTileView) {
      return (
        <TileView
          onClick={obj => this.onClick(obj)}
          events={sortedEvents}
          isTileView={isTileView}
          history={history}
        />
      );
    } else {
      return (
        <CalendarView 
          events={sortedEvents}
          onClick={obj => this.onClick(obj)}
          isTileView={isTileView}
          history={history}
        />
      )
    }
  }

  render() {
    return (
      <Container className="events-page">
        <Segment vertical className="network-search-segment">
          {this.viewSelector()}
        </Segment>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: getEvents(state)
  };
}

export default connect(mapStateToProps)(Events);
