import React, { useEffect, useState } from "react";
import { Container, Segment, Message, Input } from "semantic-ui-react";
import UserProfileGrid from "../components/admin/UserProfileGrid";
import { InviteModal } from "../components/admin/InviteModal";
import * as Profiles from "../api/profiles";

const PAGINATION_SIZE = 100;

export default function Admin() {
  const [{ profiles, total }, setProfiles] = useState({ profiles: [], total: 0 });
  const [immutableProfiles, setImmutableProfiles] = useState(profiles);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [searchIcon, setSearchIcon] = useState("search");
  const [searchValue, setSearchValue] = useState("");
  const [status, setStatus] = useState("all");

  async function updateProfiles(successMessage) {
    try {
      if (successMessage) {
        setSuccess("");
      }
      setLoading(true);
      const profiles = await Profiles.all({
        limit: PAGINATION_SIZE,
        offset: (currentPagination-1)*PAGINATION_SIZE
      });
      setSuccess(successMessage);
      setProfiles(profiles);
    } catch (err) { }
    finally { setLoading(false); }
  }

  useEffect(() => {
    Profiles
      .all({ limit: PAGINATION_SIZE, offset: (currentPagination-1)*PAGINATION_SIZE, status: status !== "all" ? status : undefined })
      .then(res => {
        setProfiles(res)
        setImmutableProfiles(res)
      })
      .finally(() => setLoading(false));
  }, [currentPagination, status]);

  async function handleSearchChange(e, { value }) {
    setSearchIcon("spinner")
    setSearchValue(value)
    try {
      if (value === "") {
        setProfiles({ profiles: immutableProfiles.profiles, total: immutableProfiles.total })
        setSearchIcon("search")
      } else {
        const items = await Profiles.getByProfileSearchValue(value);
        setProfiles({ profiles: items, total: items.length });
        setSearchIcon("search")
      }
    } catch (err) { }
  }

  async function handleDropDownChange (value) {
    setSearchValue("");
    setStatus(value);
    setCurrentPagination(1);
  }

  return (
    <Container>
      <Segment.Group horizontal>
        <Segment>
          <InviteModal onInvite={updateProfiles} isResendingInvite={false} name="" email=""/>
        </Segment>
        <Segment>
          <Input
            fluid icon={searchIcon}
            placeholder="Search..."
            onChange={handleSearchChange}
            value={searchValue}
          />
        </Segment>
      </Segment.Group>
      <Message
        error
        content={error}
        hidden={!error}
        onDismiss={() => setError("")}
      />
      <Message
        success
        content={success}
        hidden={!success}
        onDismiss={() => setSuccess("")}
      />
      <UserProfileGrid
        handleDropDownChange={handleDropDownChange}
        isTyping={searchIcon === "spinner"}
        loading={loading}
        profiles={profiles}
        onAction={updateProfiles}
        resendInvite={updateProfiles}
        currentPagination={currentPagination}
        totalPagination={Math.ceil(total/PAGINATION_SIZE)}
        setCurrentPagination={setCurrentPagination}
      />
    </Container>
  );
}
