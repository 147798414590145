import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Container,
  Segment,
  Header,
  Modal,
  Icon,
} from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { has, isEmpty, filter, includes, get } from "lodash";
import { richTextFromMarkdown } from "@contentful/rich-text-from-markdown";
import { getFullProfile, fetchUser } from "../redux/user";
import { fetchRegistredProfiles } from "../redux/profiles";
import StepOne from "../components/registration/StepOne";
import StepTwo from "../components/registration/StepTwo";
import Delayed from "../components/Delayed";
import * as Assets from "../api/assets";
import { updateProfile } from "../redux/profiles";
import "../styles//CreateProfileForms.css";

const ProfilePage = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isEditing = includes(get(location, "pathname", ""), "editProfile");
  const profile = useSelector(getFullProfile);
  const [userProfile, setUserProfile] = useState(profile.fields);
  const [saving, setSaving] = useState(false);
  const [step, setStep] = useState(1);
  const [error, setError] = useState();
  const [imageDescription, setImageDescription] = useState(get(profile, "fields.petPhoto[0].fields.description", ""));
  const [petImageDescriptionChanged, setPetImageDescriptionChanged] = useState();
  const [additionalImagesDescription, setAdditionalImagesDescription] = useState(get(profile, "fields.additionalPhotos", []).reduce(
    (obj, item, index) => Object.assign(obj, { [`description${index}`]: get(item, "fields.description", "") }), {}));
  const [additionalImagesDescriptionChanged, setAdditionalImagesDescriptionChanged] = useState({});
  const [croppedImages, setCroppedImages] = useState(userProfile['additionalPhotos'].map((photo) => get(photo, "fields.file.url")));
  const [lastImageIsCropped, setLastImageIsCropped] = useState(false);
  const [imageSelected, setImageSelected] = useState();
  const [hasProfileImage, setHasProfileImage] = useState(!!get(userProfile, "profilePicture.fields.file.url"));
  const nextStep = () => {
    let currentStep = step;
    setStep((currentStep += 1));
  };

  const handleChange = (event) => {
    return setUserProfile({
      ...userProfile,
      [event.target.name]:
        event.target.type === "number"
          ? parseInt(event.target.value)
          : event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  function handleEmailChange(value, valid) {
    if (valid) {
      setUserProfile({ ...userProfile, email: value });
    }
  }

  const handleSocialLinksButton = (socialLink, remove = false) => {
    if (!remove) {
      setUserProfile({
        ...userProfile,
        socialLinks: [...userProfile.socialLinks, socialLink],
      });
    } else {
      const currentLinks = userProfile.socialLinks.slice();
      return setUserProfile({
        ...userProfile,
        socialLinks: [...filter(currentLinks, (link) => link !== socialLink)],
      });
    }
  };

  const handleLocation = (selectedCity) =>
    setUserProfile({ ...userProfile, currentLocation: selectedCity });

  const processImages = async (images, imageDescription) => {
    let contentfulImages = [];

    for (let image of images) {
      if (!get(image, "sys.id")) {
        const { sys } = await Assets.upload(image, imageDescription);
        contentfulImages.push({
          sys: { type: "Link", linkType: "Asset", id: sys.id },
        });
      } else {
        contentfulImages.push({
          sys: { type: "Link", linkType: "Asset", id: image.sys.id },
        });
      }
    }
    return contentfulImages;
  };

  const selectedImages = (fileObject, type, index, croppedImage) => {
    
    if (index !== undefined && type === 'additionalPhotos') {
      if (croppedImage && croppedImages.indexOf(croppedImage) === -1) {
        setCroppedImages([...croppedImages, croppedImage]);
      }
      setLastImageIsCropped(true);
      setImageSelected(null);
    }
    if ( type === "profilePicture" ) {
      setHasProfileImage(true);
    }

    return setUserProfile({
      ...userProfile, [type]: type === "profilePicture" ? fileObject :  [...userProfile[type], fileObject]
    });
  }

  const handleLastImageCropped = (isCropped) => {
    setLastImageIsCropped(isCropped)
  };

  const removeAdditionalImage = (index) => {
    let additionalPhotos = [...userProfile.additionalPhotos].slice(0, index);
    let cImages = [...croppedImages].slice(0, index);
    setCroppedImages(cImages);
    setUserProfile({ ...userProfile, additionalPhotos });
  };

  const handlePodcast = (podcast, remove = false) => {
    if (!remove) {
      return setUserProfile({
        ...userProfile,
        podcasts: [...userProfile.podcasts, podcast],
      });
    } else {
      const currentPodcasts = userProfile.podcasts.slice();
      return setUserProfile({
        ...userProfile,
        podcasts: [...filter(currentPodcasts, (p) => p !== podcast)],
      });
    }
  };
  const handleImageDescriptions = (event) => {
    const hasImageId = get(profile, "fields.petPhoto[0].sys.id", false)
    const petDescriptonHasChanged = imageDescription !== event.target.value && hasImageId;
    setPetImageDescriptionChanged(petDescriptonHasChanged);
    setImageDescription(event.target.value);
  }
  const handleAdditionalImagesDescription = (event) => {
    const imageStringIndex = event.target.getAttribute("data-index");
    const hasImageId = get(profile, `fields.additionalPhotos[${imageStringIndex}].sys.id`, false)
    const descriptonHasChanged = (additionalImagesDescription !== event.target.value) && hasImageId;
    if (descriptonHasChanged) {
      setAdditionalImagesDescriptionChanged({
        ...additionalImagesDescriptionChanged, [descriptonHasChanged] : event.target.value
      });
    }
    return setAdditionalImagesDescription({
      ...additionalImagesDescription, [event.target.name] : event.target.value,
    });
  }

  const handleSubmit = async () => {
    try {
      setSaving(true);
      // update image description
      if (petImageDescriptionChanged) {
        const petImageId = petImageDescriptionChanged;
        await Assets.updateImageDescription(petImageId, imageDescription)
      }
      if (!isEmpty(additionalImagesDescriptionChanged)) {
        Object.keys(additionalImagesDescriptionChanged).forEach(async (additionalImageId) => {
          await Assets.updateImageDescription(additionalImageId, additionalImagesDescriptionChanged[additionalImageId])
        })
      }
      // upload pet and additional photos
      const hasPetPhotoId = get(userProfile, "petPhoto[0].sys.id");
      const hasReplacementPhoto = userProfile.petPhoto.length > 1;
      const newPetPhoto = hasReplacementPhoto ? [get(userProfile, "petPhoto[1]")] : userProfile.petPhoto;
      const petPhoto =  (!hasReplacementPhoto && hasPetPhotoId)
        ? [
          {
            sys: {
          type: "Link",
          linkType: "Asset",
          id: hasPetPhotoId,
        }
          }] : (!isEmpty(userProfile.petPhoto)
        ? await processImages(newPetPhoto, imageDescription)
        : []);
      const additionalPhotos = !isEmpty(userProfile.additionalPhotos)
        ? userProfile.additionalPhotos.map(async (additionalPhoto, index) => {
          const description = additionalImagesDescription[`description${index}`];
          const processedImages = await processImages([additionalPhoto], description);
          const getItem = Array.isArray(processedImages) ? processedImages.shift() : processedImages;
          return getItem;
        })
        : [];
      const contentfulProfilePicture = has(
        userProfile.profilePicture,
        "sys.id"
      )
        ? {
            sys: {
              type: "Link",
              linkType: "Asset",
              id: userProfile.profilePicture.sys.id,
            },
          }
        : await Assets.upload(userProfile.profilePicture);
      const updatedProfile = {
        ...userProfile,
        status: "registered",
        bio: await richTextFromMarkdown(userProfile.bio),
        petPhoto: petPhoto,
        additionalPhotos: await Promise.all(additionalPhotos),
        profilePicture: contentfulProfilePicture
          ? {
              sys: {
                type: "Link",
                linkType: "Asset",
                id: contentfulProfilePicture.sys.id,
              },
            }
          : null,
      };
      await dispatch(updateProfile(profile.sys.id, updatedProfile, true));
      // get updated user profile
      await dispatch(fetchUser());
      if(userProfile.status !== 'registered') {
        // profile just got published.  Need to load all profiles to get it in there.
        await dispatch(fetchRegistredProfiles());
        return history.push("/");
      }
      setSaving(false);
      return history.push(`/profile/${profile.sys.id}`);
    } catch (err) {
      console.log(err);
      setError(err);
      setSaving(false);
    }
  };

  const hasPartyId = has(userProfile, "partyId")

  return (
    <>
      {!hasPartyId && (
        <Delayed wait={1000}>
          <div className="pages">
            <Container className="no-profile-page">
              <Segment vertical>
                <Header as="h3">We are having trouble finding your profile</Header>
                <div className="no-profile-page-subheaders">
                  <p>This page is not available to employees.</p>
                  <p>If you are not an employee and still see this message.</p>
                  <p>Please contact CAA</p>
                </div>
              </Segment>
            </Container>
          </div>
        </Delayed>
      )}
      {hasPartyId && isEditing ? (
        <Container className="main-form">
          {userProfile && (
            <Segment vertical className="edit-profile">
              <Header
                as="h1"
                textAlign="center"
                content="EDIT YOUR PROFILE"
                style={{ color: "#bc1f2f", margin: "0 auto", paddingTop: 15 }}
              />
              <StepOne
                handleChange={handleChange}
                values={userProfile}
                selectedImages={selectedImages}
                handleEmailChange={handleEmailChange}
                handleLocation={handleLocation}
                isEditing={isEditing}
                hasProfileImage={hasProfileImage}
              />
              <StepTwo
                handleChange={handleChange}
                values={userProfile}
                selectedImages={selectedImages}
                handleSubmit={handleSubmit}
                handleSocialLinksButton={handleSocialLinksButton}
                removeAdditionalImage={removeAdditionalImage}
                handlePodcast={handlePodcast}
                saving={saving}
                isEditing={isEditing}
                handleAdditionalImagesDescription={handleAdditionalImagesDescription}
                additionalImagesDescription={additionalImagesDescription}
                imageDescription={imageDescription}
                handleImageDescriptions={handleImageDescriptions}
                lastImageIsCropped={lastImageIsCropped}
                handleLastImageCropped={handleLastImageCropped}
                croppedImages={croppedImages}
                imageSelected={imageSelected}
              />
            </Segment>
          )}
        </Container>
      ) : (
        <Container className="main-form">
          <Segment vertical>
            {step > 1 ? (
              <StepTwo
                handleChange={handleChange}
                values={userProfile}
                selectedImages={selectedImages}
                handleSubmit={handleSubmit}
                handleSocialLinksButton={handleSocialLinksButton}
                removeAdditionalImage={removeAdditionalImage}
                handlePodcast={handlePodcast}
                saving={saving}
                isEditing={isEditing}
                handleAdditionalImagesDescription={handleAdditionalImagesDescription}
                additionalImagesDescription={additionalImagesDescription}
                imageDescription={imageDescription}
                handleImageDescriptions={handleImageDescriptions}
                lastImageIsCropped={lastImageIsCropped}
                handleLastImageCropped={handleLastImageCropped}
                croppedImages={croppedImages}
                imageSelected={imageSelected}
              />
            ) : (
              <StepOne
                nextStep={nextStep}
                handleChange={handleChange}
                values={userProfile}
                selectedImages={selectedImages}
                handleEmailChange={handleEmailChange}
                handleLocation={handleLocation}
                isEditing={isEditing}
                hasProfileImage={hasProfileImage}
              />
            )}
          </Segment>

        </Container>
      )}
      {error && (
        <Modal
          size="small"
          open={error}
          onClose={() => setError()}
          dimmer={true}
          closeIcon={<Icon name="close" />}
        >
          <Modal.Header>Something went wrong</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              {get(error, "message", "Error updating your profile")}
            </Modal.Description>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

export default ProfilePage;
