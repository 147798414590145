import React, { useState } from "react";
import { Form } from "semantic-ui-react";

// Renders a text area for an email message.
export const MessageInput = ({ onChange, value, required }) => {
    const [message, setMessage] = useState(value || "");
    const [messageDirty, setMessageDirty] = useState(false);
    const valid = message ? true : false;
    const error =
        !valid && messageDirty
            ? {
                content: "Please enter a message",
                pointing: "above"
            }
            : null;

    function handleMessageChange(_, { value }) {
        setMessage(value);
        let isValid = value ? true : false;
        onChange(value, isValid);
    }

    return (
        <Form.TextArea
            id="message"
            label="Message"
            onChange={handleMessageChange}
            onBlur={() => setMessageDirty(true)}
            value={message}
            error={error}
            required={required}
        />
    );
}
