import React, { useState } from "react";
import EmailInput, { validateEmail } from "../EmailInput";
import { Button, Form, Grid, Header, Segment, Message } from "semantic-ui-react";
import * as Profiles from "../../api/profiles";

function NewSignup() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(validateEmail(email));
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [officeLocationWorkedIn, setOfficeLocationWorkedIn] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const valid = !!firstName && !!lastName  && !!validEmail && !!startYear && !!endYear && !!officeLocationWorkedIn;
    
   async function handleOnSubmit() {
    const entry = {
      firstName,
      lastName,
      email,
      startYear: parseInt(startYear),
      endYear: parseInt(endYear),
      officeLocationWorkedIn,
      status: "requested"
    };
    try {
      await Profiles.create(entry);
      setSuccess(true);
    }
    catch (err) {
      console.log(err);
      setError(true);
    }
  };

  function handleEmailChange(value, valid) {
    setEmail(value);
    setValidEmail(valid);
  }

  return (
    <Grid.Column computer={8} tablet={16}>
      <div className="form-container">
        <Header as="h1" className="login-header">
          START HERE
          <Header.Subheader className="login-subheader">
            We'll need to collect some important information before you join the Alumni Network.
          </Header.Subheader>
        </Header>
        <Form size="small" success={success}>
          <Segment stacked className="form-fields">
            <Form.Input
              label="First Name"
              value={firstName}
              onChange={(_, { value }) => setFirstName(value)}
            />
            <Form.Input
              label="Last Name"
              value={lastName}
              onChange={(_, { value }) => setLastName(value)} 
            />
            <EmailInput 
              label="Email" 
              value={email}
              onChange={handleEmailChange}
              />
            <Form.Group>
              <Form.Input
                width={4}
                fluid 
                label="Start Year"
                type='number'
                className="start-year"
                value={startYear}
                onChange={(_, { value }) => setStartYear(value)} 
              />
              <Form.Input
                width={4}
                fluid
                label="End Year"
                type='number'
                className="end-year"
                value={endYear}
                onChange={(_, { value }) => setEndYear(value)} 
                />
              <Form.Input
                width={8}
                fluid
                className="location-worked"
                label="CAA Office Location Worked In"
                placeholder=""
                value={officeLocationWorkedIn}
                onChange={(_, { value }) => setOfficeLocationWorkedIn(value)}
              />
            </Form.Group>
            <Message
              success
              header="Registration Request Submitted"
              content="Thank you for your submission. We will be in touch."
            />
            <Message
              error
              header={!!error ? error : "Form Not Submitted"}
            />
            <Button
              className="landing-buttons"
              size="small"
              disabled={!valid}
              type="submit"
              onClick={handleOnSubmit}
            >
              REGISTER
            </Button>
          </Segment>
        </Form>
      </div>
    </Grid.Column>
  );
}

export default NewSignup;