import axios from "axios";

export async function upload(payload, desc) {
  try {
    if(!payload) { throw new Error('Missing photo - a profile is required'); }
    const data = new FormData();
    data.append('name', payload.name);
    data.append('type', payload.type);
    data.append('file', payload);
    data.append('description', desc);
    return axios.post('/api/alumni/images', data).then((res) => res.data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function updateImageDescription(imageId, imageDescription) {
  try {
    const data = new FormData();
    data.append('description', imageDescription);
    return axios.put(`/api/alumni/images/${imageId}`, data).then((res) => res.data);
  } catch (err) {
    return Promise.reject(err);
  }
}