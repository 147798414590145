import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { filter, get, uniqBy } from 'lodash';

function DropdownFilter (props) {
  const source = props.source;
  const [dropDownVal, setdropDownVal] = useState("");
  let filteredItems;

  function handleDropdownChange(e, { value }) {
    setdropDownVal(value);
    if (props.setSelected) {
      props.setSelected(value);
    } else {
      if (value) {
        let filterValue = value;
        filteredItems = filter(source, employee => {
          let item = get(employee, props.filterBy, '').toUpperCase();

          return item === filterValue;
        })
      } else {
        filteredItems = source;
      }
      props.setFilter(filteredItems);
    }
  }

  return (
    <div>
      <Dropdown
        name={props.name}
        className={props.className}
        placeholder={props.placeholder}
        clearable
        selection
        onChange={handleDropdownChange}
        options={uniqBy(props.dropDownOptions, "value")}
        value={dropDownVal}
        selectOnBlur={false}
      ></Dropdown>
    </div>
  )
}

export default DropdownFilter;