import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { ReactComponent as BackArrow } from "../images/backArrow.svg";

export default class BackButton extends React.Component {
  render() {
    return(
      <Button size="medium" onClick={this.props.onClick} className="back-to-results" icon labelPosition='left'>
        <Icon>
          <BackArrow className="back-arrow-icon"/>
        </Icon>
        {this.props.label}
      </Button>
    )
  }
}