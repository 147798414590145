import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, Form, Message } from "semantic-ui-react";
import EmailInput, { validateEmail } from "../EmailInput";
import { get } from "lodash";
import * as Profiles from "../../api/profiles";
import { updateProfile } from "../../redux/profiles";

function EditModal({ onEdit, userProfile, disabled }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [firstName, setFirstName] = useState(userProfile.firstName);
  const [lastName, setLastName] = useState(userProfile.lastName);
  const [email, setEmail] = useState(userProfile.email);
  const [validEmail, setValidEmail] = useState(validateEmail(email));
  const [startYear, setStartYear] = useState(
    get(userProfile, "startYear", "")
  );
  const [endYear, setEndYear] = useState(
    get(userProfile, "endYear", "")
  );
  const [officeLocationWorkedIn, setOfficeLocationWorkedIn] = useState(
    get(userProfile, "officeLocationWorkedIn", "")
  );
  const [error, setError] = useState("");

  const valid = !!firstName && !!lastName && !!validEmail;

  function handleClose() {
    setOpen(false);
  }

  async function handleSave() {
    setSaving(true);
    try {
      const rawProfile = await Profiles.getByProfileId(userProfile.id);
      await dispatch(updateProfile(userProfile.id, {
        ...rawProfile.fields,
        firstName,
        lastName,
        email,
        startYear: parseInt(startYear),
        endYear: parseInt(endYear),
        officeLocationWorkedIn
      }));
      onEdit(`${firstName} ${lastName}'s profile was updated successfully.`);
      setOpen(false);
    } catch (err) {
      setError(
        `There was an error updating the profile for ${firstName} ${lastName}.`
      );
    }

    setSaving(false);
  }

  function handleEmailChange(value, valid) {
    setEmail(value);
    setValidEmail(valid);
  }

  return (
    <Modal
      open={open}
      trigger={<Button disabled={disabled} onClick={() => setOpen(true)} icon="edit" />}
    >
      <Modal.Header>
        Edit {userProfile.firstName} {userProfile.lastName}?
      </Modal.Header>
      <Modal.Content>
        <Message error content={error} hidden={!error} />
        <Form>
          <Form.Input
            required
            label="First Name"
            value={firstName}
            onChange={(_, { value }) => setFirstName(value)}
            error={!firstName}
          />
          <Form.Input
            required
            label="Last Name"
            value={lastName}
            onChange={(_, { value }) => setLastName(value)}
            error={!lastName}
          />
          <EmailInput onChange={handleEmailChange} value={email} required/>
          <Form.Input
            type="number"
            label="Start Year"
            value={startYear}
            onChange={(_, { value }) => setStartYear(value)}
          />
          <Form.Input
            type="number"
            label="End Year"
            value={endYear}
            onChange={(_, { value }) => setEndYear(value)}
          />
          <Form.Input
            label="CAA Office Location"
            value={officeLocationWorkedIn}
            onChange={(_, { value }) => setOfficeLocationWorkedIn(value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          positive
          disabled={!valid}
          onClick={handleSave}
          loading={saving}
          content="Submit"
        />
      </Modal.Actions>
    </Modal>
  );
}

export default EditModal;
