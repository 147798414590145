import React from "react";
import GTM from 'react-ga4';
import { Grid, Container, Segment } from "semantic-ui-react";
import  NewSignup  from "../components/registration/NewSignup";
import { Login } from "../components/Login";
import {ReactComponent as AlumniLogo} from "../images/alumniLogo.svg";
import "../styles/Landing.css";

export class Landing extends React.Component {
  componentDidMount(){
    GTM.initialize("G-W7VLVL7YYV"); 
  }

  render() {
    return (
      <div className="login-signup-page background-color">
        <div>
          <Grid
            padded
            className="desktop-navigation"
            verticalAlign='bottom'
          >
            <Grid.Row>
              <Grid.Column width={2}>
                <div className="alumni-logo-desktop">
                  <AlumniLogo className="alumni-white"alt="alumni"/>
                </div>
              </Grid.Column>
              <Grid.Column width={14}>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      <Container>
        <div className="pages">
          <Container className="landing">
            <Segment vertical>
              <div>
                <Grid className="forms-section" stackable>
                  <NewSignup />
                  <Login />
                </Grid>
              </div>
            </Segment>
          </Container>
        </div>
      </Container>
    </div>
    );
  }
}

export default Landing;
