import React, { useEffect } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import GTM from 'react-ga4';

import "../styles/App.css";
import "../styles/pages.css";
import { fetchProfiles } from "../redux/profiles";
import { fetchCompanies } from "../redux/companies";
import { fetchJobs } from "../redux/jobs";
import { fetchEvents } from "../redux/events";
import { fetchNews } from "../redux/news";
import { fetchSocials } from "../redux/socials";
import { fetchCarouselImages } from "../redux/carouselImages";
import { fetchMedia } from "../redux/media";
import store from "../redux/store";
import AdminRoute from "./AdminRoute";
import RegisteredRoute from "./RegisteredRoute";
import Admin from "../pages/Admin";
import HomePage from "../pages/Home";
import Careers from "../pages/Careers";
import Career from "../pages/Career";
import Contact from "../pages/Contact";
import Events from "../pages/Events";
import Event from "../pages/Event";
import Landing from "../pages/Landing";
import Network from "../pages/Network";
import News from "../pages/News";
import Profile from "../pages/Profile";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Share from "../pages/Share";
import TermsOfUse from "../pages/TermsOfUse";
import Unauthorized from "../pages/Unauthorized";
import NoProfile from "../pages/NoProfile";
import ProfilePage from "../pages/ProfilePage";
import ScrollToTop from "./ScrollToTop";
import "animate.css/animate.compat.css";
import SimpleReactLightbox from "simple-react-lightbox";
import { AppInsightsContext, insightsReactPlugin } from "../analytics";


const App = ({ history }) => {

  useEffect(() => {
    store.dispatch(fetchProfiles());
    store.dispatch(fetchCompanies());
    store.dispatch(fetchJobs());
    store.dispatch(fetchEvents());
    store.dispatch(fetchNews());
    store.dispatch(fetchSocials());
    store.dispatch(fetchCarouselImages());
    store.dispatch(fetchMedia());
    GTM.initialize("G-W7VLVL7YYV");
  }, []);

  return (
    <AppInsightsContext.Provider value={insightsReactPlugin}>
      <Provider store={store}>
        <SimpleReactLightbox>
          <div className="App">
            <Router history={history}>
              <Route render={({location}) => (
                <TransitionGroup>
                  <CSSTransition
                    timeout={300}
                    classNames="fade"
                    key={location.key}
                  >
                    <ScrollToTop>
                      <Switch location={location}>
                        <RegisteredRoute exact path="/" component={HomePage} />
                        <RegisteredRoute path="/careers" component={Careers} />
                        <RegisteredRoute path="/career/:id" component={Career} />
                        <RegisteredRoute path="/contact" component={Contact} />
                        <RegisteredRoute path="/editprofile" component={ProfilePage} />
                        <RegisteredRoute path="/events" component={Events} />
                        <RegisteredRoute path="/event/:id" component={Event} />
                        <RegisteredRoute path="/network" component={Network} />
                        <RegisteredRoute path="/news" component={News} />
                        <RegisteredRoute path="/noprofile" component={NoProfile} />
                        <RegisteredRoute path="/privacyPolicy" component={PrivacyPolicy} />
                        <RegisteredRoute path="/profile/:id" component={Profile} />
                        <RegisteredRoute path="/profile" component={Profile} />
                        <RegisteredRoute path="/share" component={Share} />
                        <RegisteredRoute path="/termsOfUse" component={TermsOfUse} />
                        <RegisteredRoute path="/welcome" component={ProfilePage} />
                        <Route path="/landing" component={Landing} />
                        <Route path="/privacy" component={PrivacyPolicy} />
                        <Route path="/terms" component={TermsOfUse} />
                        <Route path="/unauthorized" component={Unauthorized} />
                        <AdminRoute path="/admin" component={Admin} />
                      </Switch>
                    </ScrollToTop>
                  </CSSTransition>
                </TransitionGroup>
              )}/>
            </Router>
          </div>
        </SimpleReactLightbox>
      </Provider>
    </AppInsightsContext.Provider>
  );
};

export default App;
