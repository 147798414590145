import * as CFHelper from "../util/contentful";
import { map, get } from "lodash";
import axios from "axios";

function fixUpItem (item) {
  return {
    ...item.fields, 
    id: get(item, "sys.id"),
    companyLogo: CFHelper.getImageURL(get(item, "fields.companyLogo"))
  } 
};

export async function all() {
  return axios
    .get(`/api/alumni/jobs`)
    .then(response => map(response.data.items, fixUpItem));
}

export async function create(payload) {
  try {
    return axios.post('/api/alumni/jobs', CFHelper.localizeFields(payload));
  } catch (err) {
    return Promise.reject(err);
  }
}
