import React from "react";
import EmailInput from "../EmailInput";
import FileUpload from "../FileUpload";
import AddressTypeahead from "../AddressTypeahead";
import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  Container,
  Responsive
} from "semantic-ui-react";
import { get } from "lodash";

function StepOne({
  values,
  handleChange,
  nextStep,
  selectedImages,
  handleEmailChange,
  handleLocation,
  isEditing = false,
  setProfileCroppedImage,
  hasProfileImage
}) {

  return (
    <Container textAlign="center" className="create-profile-header">
      {!isEditing && (
        <Header style={{ color: "#bc1f2f", fontSize: 15 }}>
          <h5>STEP 1/2</h5>
          <h1>LET'S START YOUR PROFILE</h1>
          <p>
            Let's keep in touch and get the latest updates from your CAA Alumni
            Network.
          </p>
        </Header>
      )}
      <Grid stackable className="forms-section step-one">
        <Grid.Row>
          <Grid.Column computer={8} tablet={16}>
            <Form size="small">
              <Segment stacked className="form-fields">
                <Form.Input
                  id="firstName"
                  name="firstName"
                  fluid
                  label="First Name"
                  onChange={handleChange}
                  value={get(values, "firstName", "")}
                  required
                />
                <Form.Input
                  id="lastName"
                  name="lastName"
                  fluid
                  label="Last Name"
                  onChange={handleChange}
                  value={get(values, "lastName", "")}
                  required
                />
                <EmailInput
                  value={get(values, "email", "")}
                  onChange={handleEmailChange}
                  required={true}
                />
                <AddressTypeahead
                  currentLocation={get(values, "currentLocation", "")}
                  handleLocation={handleLocation}
                />
                <Form.Group unstackable widths="equal">
                  <Form.Input
                    id="startYear"
                    name="startYear"
                    label="Start Year"
                    type="number"
                    onChange={handleChange}
                    value={get(values, "startYear") || ""}
                    required
                  />
                  <Form.Input
                    id="endYear"
                    name="endYear"
                    label="End Year"
                    type="number"
                    onChange={handleChange}
                    value={get(values, "endYear") || ""}
                    required
                  />
                </Form.Group>
                {!isEditing && (
                  <Responsive {...Responsive.onlyComputer}>
                    <Form.Checkbox
                      id="terms"
                      name="terms"
                      label={<label data-testid="terms">I accept the <a href='/terms'>Terms of Service</a></label>}
                      type="checkbox"
                      onChange={handleChange}
                      checked={!!get(values, "terms")}
                      required
                    />
                  </Responsive>
                )}
              </Segment>
            </Form>
          </Grid.Column>

          <Grid.Column computer={8} tablet={16} className="remove-pad-mobile">
            <Form size="small">
              <Segment stacked className="form-fields">
                <Form.TextArea
                  id="bio"
                  name="bio"
                  label="Brief Professional Bio"
                  onChange={handleChange}
                  value={get(values, "bio", "")}
                  required
                />

                <Header.Subheader
                  style={{
                    textAlign: "left",
                    color: "#bc1f2f",
                    fontWeight: 300,
                    fontSize: 13,
                    marginBottom: ".28571429rem"
                  }}
                >
                  Upload Profile Picture *
                </Header.Subheader>
                <FileUpload
                  style={{ overflow: "none" }}
                  selectedImages={selectedImages}
                  setProfileCroppedImage={setProfileCroppedImage}
                  imageSrc={get(values, "profilePicture.fields.file.url")}
                  type="profilePicture"
                />
                {!isEditing && (
                  <Responsive maxWidth={990}>
                    <Form.Checkbox
                    id="terms"
                    name="terms"
                    label={<label data-testid="terms">I accept the <a href='/terms'>Terms of Service</a></label>}
                    type="checkbox"
                    onChange={handleChange}
                    checked={!!get(values, "terms")}
                    required
                    className="show-checkbox-on-mobile"
                    />
                  </Responsive>
                )}
              </Segment>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {!isEditing && (
        <div
          className="mandatory-print"
          style={{
            paddingBottom: 15,
            width: "50%",
            margin: "30px auto",
          }}
        >
          <p style={{color: "#BC1F2F"}}>Items marked with asterisk (*) are mandatory</p>
          <Button
            className="profile-buttons"
            size="small"
            onClick={nextStep}
            disabled={
              !values.firstName ||
              !values.lastName ||
              !values.email ||
              !values.currentLocation ||
              !values.startYear ||
              !values.endYear ||
              !values.bio ||
              !values.terms ||
              !hasProfileImage
            }
          >
            CONTINUE
          </Button>
        </div>
      )}
    </Container>
  );
}

export default StepOne;
