import * as Assets from "./assets";
import * as CFHelper from "../util/contentful";
import { get, map } from "lodash";
import axios from "axios";

function fixUpItem(item) {
  return {
    ...item.fields,
    id: get(item, "sys.id"),
    image: CFHelper.getImageURL(get(item, "fields.image"))
  };
}

export async function all() {
  return axios
    .get(`/api/alumni/events`)
    .then((response) => map(response.data.items, fixUpItem));
}

export async function create(payload) {
  try {
    if (payload.eventPhoto) {
      const asset = await Assets.upload(payload.eventPhoto);
      payload.image = {
        sys: {
          id: asset.sys.id,
          linkType: "Asset",
          type: "Link",
        },
      };
      delete payload.eventPhoto;
    }
    return axios.post("/api/alumni/events", CFHelper.localizeFields(payload));
  } catch (err) {
    return Promise.reject(err);
  }
}
