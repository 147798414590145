import * as Events from "../api/events";
import { createSlice, createSelector } from "@reduxjs/toolkit";
import * as CFHelper from "../util/contentful";
import { getUser } from "./user";

const initialState = [];

let events = createSlice({
  name: "events",
  initialState: initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setEvents: (_state, action) => {
      return action.payload;
    }
  }
});

export const { reset, setEvents } = events.actions;

export const getEvents = createSelector(
  state => state.events,
  events => events
);

export function fetchEvents() {  
  return async (dispatch) => {
    try {
      const events = await Events.all();
      dispatch(setEvents(events));
    } catch (err) {
      console.log(err);
    }
  };
}

export function createEvent(event) {  
  return async (dispatch, getState) => {
    CFHelper.addContributorInfo(event, getUser(getState()));
    await Events.create(event);
  };
}

export default events.reducer;
