import React, { useState } from "react";
import { Form } from "semantic-ui-react";

// Renders an input for an email subject.
export const SubjectInput = ({ onChange, value, required }) => {
    const [subject, setSubject] = useState(value || "");
    const [subjectDirty, setSubjectDirty] = useState(false);
    const valid = subject ? true : false;
    const error =
        !valid && subjectDirty
            ? {
                content: "Please enter a subject",
                pointing: "above"
            }
            : null;

    function handleSubjectChange(_, { value }) {
        setSubject(value);
        let isValid = value ? true : false;
        onChange(value, isValid);
    }

    return (
        <Form.Input
            id="subject"
            label="Subject"
            onChange={handleSubjectChange}
            onBlur={() => setSubjectDirty(true)}
            value={subject}
            error={error}
            required={required}
        />
    );
}
