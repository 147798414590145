import * as Users from "../api/users";
import * as Profiles from "../api/profiles";
import { get, map } from "lodash";
import { createSlice, createSelector } from "@reduxjs/toolkit";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";

const initialState = {
  user: {},
  rawProfile: {},
  profile: {},
  error: null
};

let user = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setContentfulProfile: (state, action) => {
      state.rawProfile = action.payload;
      state.profile = Profiles.fixUpItem(action.payload);
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { reset, setUser, setContentfulProfile, setError } = user.actions;

export const getUserProfile = createSelector(
  state => state.user.rawProfile,
  user => user
);

export const getSocialLinks = createSelector(getUserProfile, entry =>
  get(entry, "fields.socialLinks", [])
);

export const getPodcasts = createSelector(getUserProfile, entry =>
  get(entry, "fields.podcasts", [])
);

export const getProfileId = createSelector(getUserProfile, entry => 
  get(entry, "sys.id", [])
);

export const getFirstName = createSelector(
  getUserProfile,
  state => state.user.user,
  (entry, user) => get(entry, "fields.firstName", get(user, "firstName", ""))
);

export const getProfilePicture = createSelector(
  state => state.user.profile,
  state => state.user.user,
  (profile, user) =>
   user.isExternal ? 
    get(profile, "profilePicture", "") : `/api/parties/${user.partyId}/defaultImage?format=thumbnail`  
);

export const getUser = createSelector(
  state => state.user.profile,
  state => state.user.user,
  getProfilePicture,
  (profile, user, profilePicture) => {
    const isAdmin =
      Array.isArray(user.scope) &&
      (user.scope.includes("CAA\\SEC-ALUMNI-EDITORS") ||
        user.scope.includes("CAA\\SEC-APPLICATIONDELIVERY"));
    const unregistered =
      user.isExternal &&
      !(
        profilePicture &&
        profile.firstName &&
        profile.lastName &&
        profile.email &&
        profile.currentLocation &&
        profile.startYear &&
        profile.endYear &&
        profile.bio
      );
    return { ...user, isAdmin, unregistered };
  }
);

export function fetchUser() {
  return async dispatch => {
    try {
      const user = await Users.get();
      const partyId = get(user, "partyId");
      if (partyId) {
        const userProfile = await Profiles.getProfile();
        if (userProfile) {
          // console.log("contentful profile: ", userProfile);
          dispatch(setContentfulProfile(userProfile));
        } else {
          console.log("no profile for user ", partyId);
        }
      } else {
        console.log("no partyId for user! ", user);
      }
      dispatch(setUser(user));
    } catch (err) {
      dispatch(setError(err));
    }
  };
}

export const getRawProfilePicture = createSelector(getUserProfile, entry =>
  get(entry, "fields.profilePicture")
);

export const getRawPetPhoto = createSelector(getUserProfile, entry =>
  get(entry, "fields.petPhoto", [])
);

export const getRawAdditionalPhotos = createSelector(getUserProfile, entry =>
  get(entry, "fields.additionalPhotos", [])
);

export const getBio = createSelector(getUserProfile, entry =>
  map(get(entry, "fields.bio.content", []), p =>
    documentToPlainTextString(p)
  ).join(",")
);

export const getFullProfile = createSelector(
  getUserProfile,
  getSocialLinks,
  getPodcasts,
  getRawProfilePicture,
  getRawPetPhoto,
  getRawAdditionalPhotos,
  getBio,
  (
    entry,
    socialLinks,
    podcasts,
    profilePicture,
    petPhoto,
    additionalPhotos,
    bio
  ) => {
    return {
      ...entry,
      fields: {
        ...entry.fields,
        socialLinks,
        podcasts,
        profilePicture,
        petPhoto,
        additionalPhotos,
        bio
      }
    };
  }
);

export default user.reducer;
