import * as CFHelper from "../util/contentful";
import { map, get } from "lodash";
import axios from "axios";

function fixUpItem (item) {
  return {
    ...item.fields,
    id: get(item, "sys.id"),
    title: get(item, "fields.title", ""),
    link: get(item, "fields.link", ""),
    image: CFHelper.getImageURL(get(item, "fields.image"))
  };
}

export async function all() {
  return axios
    .get(`/api/alumni/carouselImages`)
    .then(response => map(response.data.items, fixUpItem));
}