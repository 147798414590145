import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Grid, Header, Segment, Button } from "semantic-ui-react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { get } from "lodash";
import { getJobs } from "../redux/jobs";
import BackButton from "../components/BackButton";

function Career (props) {
  const { match: { params } } = props;
  const jobPostId = get(params, "id");
  const { jobs } = props;
  const jobPost = jobs.find((p) => p.id === jobPostId);
  const jobPostDate = get(jobPost, "datePosted", null);
  let link = get(jobPost, "applicationLink", "").toLowerCase();

  if(link && /(http(s?)):\/\//i.test(link) === false) {
    if(link.indexOf("@") > 0) {
      link = "mailto:" + link;
    }
    else {
      link = "https://" + link;
    }
  }

  return (
    <>
      {!jobPost && (
        <div className="pages">
          <Container className="no-career-post-page">
            <Segment vertical>
              <Header as="h3">We are having trouble finding the career post you selected</Header>
            </Segment>
          </Container>
        </div>
      )}
      {!!jobPost && (
        <Container className="careers-page">
          <Segment vertical className="careers-segment">
            <Grid
              stackable
              padded
              textAlign="left"
              verticalAlign="middle"
              columns={2}
            >
            <Grid.Column width={16}>
            <Link to="/careers">
              <BackButton
                label="BACK TO OPPORTUNITIES"
              />
            </Link>
            </Grid.Column>
            </Grid>
            <Grid columns="equal" className="main-article" stackable container>
              <Grid.Column className="career-detail-image">
                <div
                className="careers-image-tile"
                style={{
                  backgroundImage: `url(${get(jobPost, "companyLogo.url", "")})`
                }}
                >
                </div>
              </Grid.Column>
              <Grid.Column width={12}>
                <div className="single-image-detail-text">
                  <h1 className="company-title">
                    {jobPost.title}
                  </h1>
                  <Grid verticalAlign="bottom" columns={2} className="job-post-sub-detail">
                    <Grid.Row>
                      <Grid.Column>
                        <p className="post-company">
                        {jobPost.company} 
                        <br/>
                        {jobPost.location}
                        <br/>
                        Posted: {!!jobPostDate && (
                          new Intl.DateTimeFormat("en-US", {year: "numeric", month:"long", day: "numeric"}).format(new Date(jobPostDate))
                        )}
                        </p>
                      </Grid.Column>
                      <Grid.Column>
                        {link && (
                          <div className="careers-page-button-container">
                            <Button
                              className="careers-apply-button"
                              as="a"
                              size="medium"
                              href={link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              APPLY
                            </Button>
                          </div>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
                <div className="section-divider">
                  <hr></hr>
                </div>
                <div className="job-post-text">
                  <h3>DESCRIPTION</h3>
                  {documentToReactComponents(jobPost.description)}
                </div>
              </Grid.Column>
            </Grid>
        </Segment>
      </Container>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    jobs: getJobs(state)
  };
}

export default connect(mapStateToProps)(Career);
