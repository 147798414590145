import * as CarouselImages from "../api/carouselImages";
import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = [];

let carouselImages = createSlice({
  name: "carouselImages",
  initialState: initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setCarouselImages: (_state, action) => {
      return action.payload;
    }
  }
});

export const { reset, setCarouselImages } = carouselImages.actions;

export const getCarouselImages = createSelector(
  state => state.carouselImages,
  carouselImages => carouselImages
);


export function fetchCarouselImages() {  
  return async (dispatch) => {
    try {
      const carouselImages = await CarouselImages.all();
      dispatch(setCarouselImages(carouselImages));
    } catch (err) {
      console.log(err);
    }
  };
}

export default carouselImages.reducer;
