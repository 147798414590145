import React from "react";
import { useDispatch } from "react-redux";
import ShareForms from './ShareForms';
import * as Jobs from "../../redux/jobs";

export default function JobPosting () {
  const dispatch = useDispatch();
  return (
    <ShareForms
      className="job-posting"
      formName="job posting"
      header="OPPORTUNITY"
      subHeader="Please note that opportunity postings will be removed after two weeks." 
      labelOne="Job Title"
      fieldOne="title"    
      labelTwo="Company"    
      fieldTwo="company"
      labelThree="Location"
      fieldThree="location"
      handleSubmit={(data) => dispatch(Jobs.createJob(data))}
      animationDuration={0.5}
      animationDelay={1}
    />
  );
}