import React from "react";
import { useDispatch } from "react-redux";
import ShareForms from './ShareForms';
import * as Events from "../../redux/events";

export default function EventPosting () {
  const dispatch = useDispatch();
  return (
    <ShareForms
      className="event-posting"
      formName="event"
      header="EVENT" 
      labelOne="Event Title"
      fieldOne="title"
      labelTwo="Location"
      fieldTwo="location"
      handleSubmit={(data) => dispatch(Events.createEvent(data))}
      animationDuration={0.5}
      animationDelay={1}
    />
  );
}
        