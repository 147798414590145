import React, { useState } from "react";
import { Form, Label, Icon } from "semantic-ui-react";
import { map } from "lodash";

const Podcasts = ({ values, handlePodcast }) => {
  const [newPodcast, setNewPodcast] = useState("");

  const handleChange = (e, { value }) => setNewPodcast(value);

  const addPodcast = e => {
    if (e.key === "Enter") {
      handlePodcast(newPodcast);
      setNewPodcast("");
    }
  };

  const removePodcast = (podcast) => handlePodcast(podcast, true);

  return (
    <div>
      <Form.Input
        label="What podcast are you listening to? (Press enter to submit)"
        onKeyPress={addPodcast}
        onChange={handleChange}
        value={newPodcast}
      />

      {values.podcasts.length > 0 && (
        <Label.Group className="podcasts">
          {map(values.podcasts, (podcast, i) => (
            <Label
              style={{ color: "#fff", backgroundColor: "#ababab" }}
              key={i}
            >
              {podcast.toUpperCase()}{" "}
              <Icon name="close" onClick={() => removePodcast(podcast)} />
            </Label>
          ))}
        </Label.Group>
      )}
    </div>
  );
};

export default Podcasts;
