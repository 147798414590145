import React, { useState } from "react";
import { debounce } from "lodash";
import { Button, Search, Modal, Form, Message } from "semantic-ui-react";
import * as Employees from "../../api/employees";
import * as Profiles from "../../api/profiles";
import * as Parties from "../../api/parties";
import { map } from "lodash";
import * as UserHelpers from "../../util/user";

function resultRenderer({ title }) {
  return <div>{title}</div>;
}

function ApprovalModal({ onApproved, userProfile, disabled }) {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [employee, setEmployee] = useState();
  const [open, setOpen] = useState(false);
  const [approving, setApproving] = useState(false);
  const [error, setError] = useState("");

  async function handleSearchChange(e, { value }) {
    setLoading(true);
    setValue(value);

    try {
      const searchResults = await Employees.typeahead(value);
      setResults(
        map(searchResults, r => ({
          title: r.name,
          id: r.id
        }))
      );
      setLoading(false);
    } catch (err) {
      setError("An error was encountered when searching.");
    }
  }

  async function handleResultSelect(_, { result }) {
    try {
      const party = await Parties.get(result.id);
      setEmployee(party);
      setValue(result.title);
    } catch (err) {
      setError(`An error occurred when adding ${result.title}.`);
    }
  }

  function handleClose() {
    setOpen(false);
    setResults([]);
    setEmployee();
    setValue("");
    setError("");
  }

  async function handleApproval() {
    setApproving(true);
    try {
      const rawProfile = await Profiles.getByProfileId(userProfile.id);
      await UserHelpers.approve(rawProfile, employee.id);
      await Parties.patch(employee.id, [
        {
          op: "add",
          path: `/externalIds/${employee.externalIds.length}`,
          value: {
            typeId: 51,
            type: "AlumniProfileId",
            externalId: userProfile.id
          }
        }
      ]).catch(() => {
        setError("An error occurred when adding the profile id to the party.");
      });
      onApproved();
    } catch (err) {
      setError("An error occurred when approving the profile.");
    }

    setApproving(false);
  }

  return (
    <Modal
      open={open}
      trigger={
        <Button onClick={() => setOpen(true)} positive content="Approve" disabled={disabled} />
      }
    >
      <Modal.Header>
        Who is {userProfile.firstName} {userProfile.lastName}?
      </Modal.Header>
      <Modal.Content>
        <Message error content={error} hidden={!error} />
        <Form>
          <Form.Field>
            <label>Employee</label>
            <Search
              placeholder="Find an employee"
              loading={loading}
              onResultSelect={handleResultSelect}
              onSearchChange={debounce(handleSearchChange, 500, {
                leading: true
              })}
              results={results}
              resultRenderer={resultRenderer}
              value={value}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          positive
          disabled={!employee}
          onClick={handleApproval}
          loading={approving}
          content="Submit"
        />
      </Modal.Actions>
    </Modal>
  );
}

export default ApprovalModal;
