import * as Profiles from "../api/profiles";
import * as ExternalInvitations from "../api/externalInvitations";
import { get } from "lodash";

export async function approve(rawprofile, partyId) {
  if (get(rawprofile, "fields.status")) {
    const fields = { ...rawprofile.fields, status: "invited", partyId: partyId };
    const email = get(rawprofile, "fields.email");
    if (email) {
      await Profiles.updateDraft(rawprofile.sys.id, fields);
      await ExternalInvitations.invite(
        partyId,
        get(rawprofile, "fields.firstName") + " " + get(rawprofile, "fields.lastName"),
        email
      );
    }
  }
}

export function reject(rawprofile) {
  if (get(rawprofile, "fields.status")) {
    const fields = { ...rawprofile.fields, status: "rejected" };
    return Profiles.updateDraft(rawprofile.sys.id, fields);
  }
}
