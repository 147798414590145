import React from "react";
import { Container, Segment, Header, Divider } from "semantic-ui-react";

export default class Contact extends React.Component {
  render() {
    return (
      <Container className="about-page">
        <Segment vertical>
          <Header as="h3">ABOUT</Header>
          <div className="about-page-subheaders">
            <p>
              When you join CAA, you become part of our history – even if your
              career eventually leads you elsewhere.
            </p>
            <p>
              CAA Alumni are located all over the world. It occurred to us that
              although our alumni keep in touch personally and professionally,
              there was no place to gather as a community. Our hope is that you
              use this platform to connect, discover and contribute.
            </p>
            <p>
              Benefits of the CAA Alumni Network include ongoing updates about
              current and former colleagues, relevant job postings and exclusive
              content just for you.
            </p>
            <p className="stay-connected">LET’S STAY CONNECTED.</p>
            <p className="welcome">WELCOME TO THE CAA ALUMNI NETWORK.</p>
          </div>
          <Divider section/>
          <Header as="h3">CONTACT</Header>
          <div className="about-page-subheaders">
            <p>
              We are constantly looking for ways to improve our CAA Alumni
              experience.
            </p>
            <p>
              Want to share some feedback? Drop us a note at{" "}
              <a href="mailto:CAAAlumni@caa.com"> CAAAlumni@caa.com</a>
            </p>
          </div>
        </Segment>
      </Container>
    );
  }
}
