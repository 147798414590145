import { get, map } from "lodash";

// Turns standard JSON properties into localized contentful-compatible objects
export function localizeFields(payload, locale = "en-US") {
  const fields = {};
  for (let key in payload) {
    if (!!payload[key]) {
      fields[key] = {[locale]: payload[key]};
    }
  }
  return fields;
}

// Adds the logged in user's name, email, and partyId to submitted items
export function addContributorInfo(item, profile) {
  item.contributorName = get(profile, "name", "");
  item.contributorEmail = get(profile, "emailAddress", "");
  item.contributorPartyId = get(profile, "partyId", "");
}

// Turns asset fields from complex objects with sys and fields to just string urls
export function getImageURL(item) {
  return {
    caption: get(item, "fields.description", ""),
    url: get(item, "fields.file.url", "#")
  };
}

// Turns linked entry fields from complex objects with sys and fields to a simple object with id and name
export function getLinkedEntry(item) {
  return {
    id: get(item, "sys.id", ""),
    name: get(item, "fields.name", "")
  };
}

export function getImageURLs(items) {
  return map(items, getImageURL);
}

