import { combineReducers } from "@reduxjs/toolkit";
import user from "./user";
import app from "./app";
import profiles from "./profiles";
import jobs from "./jobs";
import events from "./events";
import news from "./news";
import companies from "./companies";
import socials from "./socials";
import carouselImages from "./carouselImages";
import media from "./media";

export default combineReducers({
  app,
  user,
  profiles,
  jobs,
  events,
  news,
  companies,
  socials,
  carouselImages,
  media
});
