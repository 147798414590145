import * as CFHelper from "../util/contentful";
import { get, map, filter, startCase } from "lodash";
import axios from "axios";


function fixUpItem (item) {
  let images, title, description, skip;
  const type = get(item, "sys.contentType.sys.id", "");
  switch (type) {
    case 'profile': 
      images =  CFHelper.getImageURL(get(item, "fields.profilePicture"));
      title = get(item, "fields.firstName").concat(" ", get(item, "fields.lastName"));
      description = get(item, "fields.currentLocation", "");
      break;
    case 'event':
      images = CFHelper.getImageURL(get(item, "fields.image"));
      title = get(item, "fields.title", "");
      description = get(item, "fields.location", "");
      break;
    case 'news':
      images = CFHelper.getImageURL(get(item, "fields.logo"));
      title = get(item, "fields.title", "");
      break;
    case 'jobPosting':
      title = get(item, "fields.title", "");
      description = get(item, "fields.location", "");
      break;
    default:
      // console.log('default: ', type)
      skip = true;
      break;
  }
  return skip ? {} : {
    ...item.fields,
    id: get(item, "sys.id"),
    title: title,
    image: images,
    description: description,
    category: startCase(get(item, "sys.contentType.sys.id"))
  };
}

export async function getBySearchValue(searchValue) {
  return axios
    .get(`/api/alumni:search?terms=${searchValue}`)
    .then(response => filter(map(response.data.items, fixUpItem), (i) => i.id));
}