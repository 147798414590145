import * as CFHelper from "../util/contentful";
import { map, get } from "lodash";
import axios from "axios";

function fixUpItem (item) {
  return {
    ...item.fields,
    id: get(item, "sys.id"),
    description: get(item, "fields.description", ""),
    network: get(item, "fields.network", ""),
    logo: CFHelper.getImageURL(get(item, "fields.logo"))
  };
}

export async function all() {
  return axios
    .get(`/api/alumni/socialAccounts`)
    .then(response => map(response.data.items, fixUpItem));
}