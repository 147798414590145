import React, { useState } from "react";
import { Search } from 'semantic-ui-react';
import * as GlobalSearch from "../api/globalsearch";
import { get, groupBy } from "lodash";
import { useHistory } from "react-router-dom";


function GlobalSearchBar(props) {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState([]);
  
  function handleResultSelect(e, { result }) {
    let name;
    switch (result.category) {
      case 'Profile':
        name = 'profile';
        break;
      case 'News':
        name = 'article';
        break;
      case 'Job Posting':
        name = 'career';
        break;
      case 'Event':
        name = 'event';
        break;
      default:
        console.log('result.id', result.id)
    }
    history.push({
      pathname: `/${name}/${result.id}`
    })
  }

  function groupCategory(categories) {
    let groupedCategory = groupBy(categories, cat => cat.category);
  
    Object.keys(groupedCategory).forEach((category) => {
      const results = groupedCategory[category];
      groupedCategory[category] = {
        name: category,
        results: results.map(item =>
          (item = {
            title: get(item, "title", ""),
            description: get(item, "description", ""),
            image: get(item,"image.url", ""),
            id: get(item, "id", ""),
            category: get(item, "category", "") 
          }))
      }
    })
    return groupedCategory;
  }

  async function handleSearchChange(e, { value }) {
    setSearchValue(value)
    try {
      const items = await GlobalSearch.getBySearchValue(searchValue);
      const groupedItems = groupCategory(items);
      setResults(groupedItems);
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <Search
        aligned='left'
        category 
        className={props.className}
        placeholder="SEARCH"
        onResultSelect={handleResultSelect}
        onSearchChange={handleSearchChange}
        results={results}
      />
    </div>
  )
}

export default GlobalSearchBar;