import React, { useRef, useState, useEffect } from "react";
import FileUpload from "../FileUpload";
import SocialLinks from "../SocialLinks";
import AdditionalPhotos from "../AdditionalPhotos";
import Podcasts from "../Podcasts";
import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  Container,
  Icon,
} from "semantic-ui-react";
import { get } from "lodash";

function StepTwo({
  values,
  handleChange,
  selectedImages,
  handleSubmit,
  handleSocialLinksButton,
  handleImageDescriptions,
  handleAdditionalImagesDescription,
  removeAdditionalImage,
  handlePodcast,
  saving,
  isEditing,
  imageDescription,
  additionalImagesDescription,
  lastImageIsCropped,
  handleLastImageCropped,
  croppedImages,
  imageSelected
}) {
  const [selectedImage, setSelectedImage] = useState(imageSelected);
  const [selectedImageName, setSelectedImageName] = useState();
  const [selectedImageType, setSelectedImageType] = useState();
  const [additionalPhotos, setAdditionalPhotos] = useState(values.additionalPhotos)
  const [croppedPhotos, setCroppedPhotos] = useState(croppedImages)
  const additionalImagesRef = useRef(null);
  const additionalImagesOnChangeHandler = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      
      reader.addEventListener('load', () => {
        setSelectedImage(reader.result)
      });
      reader.readAsDataURL(e.target.files[0]);
      setAdditionalPhotos([...additionalPhotos, e.target.files[0]]);
      setSelectedImageName(e.target.files[0].name);
      setSelectedImageType(e.target.files[0].type);
      handleLastImageCropped(false);
    }
  }

  useEffect(() => {
    setAdditionalPhotos(values.additionalPhotos);
    setCroppedPhotos(croppedImages);
    setSelectedImage(imageSelected)
  }, [values, croppedImages, imageSelected]) ;

  return (
    <Container textAlign="center" className="create-profile-header">
      {!isEditing && (
        <Header style={{ color: "#bc1f2f", fontSize: 15 }}>
          <h5>STEP 2/2</h5>
          <h1>WHAT HAVE YOU BEEN UP TO?</h1>
          <p>c'mon!</p>
        </Header>
      )}
      <Grid stackable className="forms-section step-two">
        <Grid.Row>
          <Grid.Column computer={8} tablet={16}>
            <Form size="small">
              <Segment stacked className="form-fields">
                <Form.Input
                  fluid
                  id="currentJobTitle"
                  name="currentJobTitle"
                  label="Current Job Title"
                  onChange={handleChange}
                  value={get(values, "currentJobTitle", "")}
                />
                <Form.Input
                  id="currentCompany"
                  name="currentCompany"
                  label="Current Company"
                  onChange={handleChange}
                  value={get(values, "currentCompany", "")}
                />

                <Header.Subheader
                  style={{
                    textAlign: "left",
                    color: "#bc1f2f",
                    fontWeight: 300,
                    fontSize: 13,
                    marginBottom: ".28571429rem"
                  }}
                >
                  Share Photos of Your Favorite Beings
                </Header.Subheader>
                <p style={{ textAlign: "left", color: "#bc1f2f", marginBottom: ".28571429rem" }}>
                  (kids, dogs, cats, etc.)
                </p>

                <FileUpload
                  selectedImages={selectedImages}
                  type="petPhoto"
                  imageSrc={get(values, "petPhoto[0].fields.file.url")}
                />
                <Form.Input
                  id="petPhotoDescription"
                  name="petPhotoDescription"
                  label="Add Image Caption"
                  onChange={handleImageDescriptions}
                  value={imageDescription}
                />


                <AdditionalPhotos
                  selectedImage={selectedImage}
                  selectedImageName={selectedImageName}
                  selectedImageType={selectedImageType}
                  selectedImages={selectedImages}
                  additionalPhotos={additionalPhotos}
                  handleRemove={removeAdditionalImage}
                  handleAdditionalImagesDescription={handleAdditionalImagesDescription}
                  additionalImagesDescription={additionalImagesDescription}
                  addtionalLastPhotoIsCropped={lastImageIsCropped}
                  croppedImages={croppedPhotos}
                />


                {values.petPhoto.length > 0 && (
                  <div
                    style={{
                      paddingTop: 20,
                      paddingBottom: 20,
                      textAlign: "right",
                      width: "90%",
                    }}
                  >
                    <input
                      hidden
                      ref={additionalImagesRef}
                      type="file"
                      onChange={additionalImagesOnChangeHandler}
                    />
                    <Button
                      icon={<Icon name="plus" />}
                      style={{ background: "none", color: "#bc1f2f" }}
                      content="Add an Additional Photo"
                      onClick={() => additionalImagesRef.current.click()}
                      disabled={values.additionalPhotos.length === 10} // disable if they already have 10 photos
                    />
                  </div>
                )}
              </Segment>
            </Form>
          </Grid.Column>
          <Grid.Column computer={8} tablet={16}>
            <Form size="small">
              <Segment stacked className="form-fields">
                <SocialLinks
                  links={values.socialLinks}
                  handleSocialLinksButton={handleSocialLinksButton}
                />
                <Form.Input
                  id="travelingTo"
                  name="travelingTo"
                  label="Where are you traveling next?"
                  onChange={handleChange}
                  value={get(values, "travelingTo", "")}
                />
                <Form.Input
                  id="readingNow"
                  name="readingNow"
                  label="What are you reading now"
                  onChange={handleChange}
                  value={get(values, "readingNow", "")}
                />
                <Form.TextArea
                  id="proudOf"
                  name="proudOf"
                  label="What are you proud of?"
                  onChange={handleChange}
                  value={get(values, "proudOf", "")}
                  style={{minHeight: "242px"}}
                />
                <Podcasts values={values} handlePodcast={handlePodcast} />
              </Segment>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div
        style={{
          width: "50%",
          margin: "30px auto"
        }}
      >
        <p style={{color: "#BC1F2F"}}>Items marked with asterisk (*) are mandatory</p>
        <Button
          className="profile-buttons"
          size="small"
          onClick={handleSubmit}
          disabled={saving}
          loading={saving}
        >
          FINISH YOUR PROFILE
        </Button>
      </div>
    </Container>
  );
}

export default StepTwo;
