import * as Profiles from "../api/profiles";
import { createSlice, createSelector } from "@reduxjs/toolkit";
import map from "lodash/map";
const initialState = [];

let profiles = createSlice({
  name: "profiles",
  initialState: initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setProfiles: (_state, action) => {
      return action.payload;
    }
  }
});

export const { reset, setProfiles } = profiles.actions;

export const getProfiles = createSelector(
  state => state.profiles,
  profiles => profiles
);

export function fetchProfiles() {  
  return async (dispatch) => {
    try {
      const profiles = await Profiles.registered();
      dispatch(setProfiles(profiles));
    } catch (err) {
      console.log(err);
    }
  };
}

export function fetchRegistredProfiles() {  
  return async (dispatch) => {
    try {
      const profiles = await Profiles.registered();
      dispatch(setProfiles(profiles));
    } catch (err) {
      console.log(err);
    }
  };
}

export function updateProfile(id, profile, isUser) {  
  return async (dispatch, getState) => {
    try {
      const profiles = getState().profiles;
      let updated = await Profiles.update(id, profile, isUser);
      updated = Profiles.fixUpItem(updated);
      dispatch(setProfiles(map(profiles, (p) => {
        if(p.id === updated.id) {
          return updated;
        }
        else {
          return p;
        }
      })));
    } catch (err) {
      console.log(err);
    }
  };
}

export default profiles.reducer;
