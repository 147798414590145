import React from "react";
import { Container, Segment, Header } from "semantic-ui-react";

const NoProfile = () => (
  <div className="pages">
    <Container className="no-profile-page">
      <Segment vertical>
        <Header as="h3">We are having trouble finding the requested profile</Header>
      </Segment>
    </Container>
  </div>
);

export default NoProfile;
