import React, { useState } from "react";
import { debounce } from "lodash";
import { Button, Search, Modal, Form, Message } from "semantic-ui-react";
import * as Employees from "../../api/employees";
import * as Parties from "../../api/parties";
import * as ExternalUsers from "../../api/externalUsers";
import * as Profiles from "../../api/profiles";
import { map } from "lodash";
import * as PartyHelpers from "../../util/party";
import EmailInput from "../EmailInput";
import { SubjectInput } from "../SubjectInput";
import { MessageInput } from "../MessageInput";
import * as ExternalInvitations from "../../api/externalInvitations";

const partyIdProp = "extension_b35034c719d641f2aee93d3c7b436153_CAA_MDM_ID";

function resultRenderer({ title }) {
  return <div>{title}</div>;
}

const subjectDefault = "Invitation from Creative Artists Agency";
const messageDefault =
  "As an important member of the CAA family, you are one of the first to be invited to join the CAA Alumni Network, a new platform dedicated to keeping you connected to former colleagues and the agency. Join by clicking the link below to create your profile.";

const useInvite = (onInvite) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [employee, setEmployee] = useState();
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState(subjectDefault);
  const [message, setMessage] = useState(messageDefault);
  const [validSubject, setValidSubject] = useState(true);
  const [validMessage, setValidMessage] = useState(true);
  const [validEmail, setValidEmail] = useState(false);
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [warning, setWarning] = useState("");
  const [error, setError] = useState("");

  const valid =
    validEmail && employee && employee.id && validSubject && validMessage;

  async function handleSearchChange(e, { value }) {
    setLoading(true);
    setValue(value);

    try {
      const searchResults = await Employees.typeahead(value);
      setResults(
        map(searchResults, (r) => ({
          title: r.name,
          id: r.id,
        }))
      );
    } catch (err) {
      setError("An error was encountered while searching.");
    }
    setLoading(false);
  }

  async function handleResultSelect(_, { result }) {
    try {
      const partyPromise = Parties.get(result.id);
      const alumniProfilePromise = Profiles.getByProfilePartyId(result.id);

      const party = await partyPromise;
      const alumniProfile = await alumniProfilePromise;

      if (alumniProfile.items.length > 0) {
        setWarning("This employee has already been invited.");
      } else {
        setError("");
        setWarning("");
        setEmployee(party);
        setValue(result.title);
      }
    } catch (err) {
      setError(`An error occurred when adding ${result.title}.`);
    }
  }

  function handleSubjectChange(value, valid) {
    setSubject(value);
    setValidSubject(valid);
  }

  function handleMessageChange(value, valid) {
    setMessage(value);
    setValidMessage(valid);
  }

  function handleEmailChange(value, valid) {
    setEmail(value);
    setValidEmail(valid);
  }

  // Handles the close of the modal.
  function handleClose() {
    setOpen(false);
    setResults([]);
    setEmployee();
    setValue("");
    setEmail("");
    setSubject(subjectDefault);
    setMessage(messageDefault);
  }

  // Gets the party for the previously invited alumni
  async function getAlumniParty(partyId, alumniEmail) {
    setLoading(true);

    try {
      const partyPromise = Parties.get(partyId);
      const party = await partyPromise;

      setError("");
      setWarning("");
      setEmail(alumniEmail);
      setEmployee(party);
      setValue(party.name);
      // Because we are using the same email as was previously entered in the original invitation, we know this email is valid.
      setValidEmail(true);
    } catch (err) {
      setError(`An error occurred.`);
    }

    setLoading(false);
  }

  // Checks to see if the email is already in use by an existing external user.
  async function checkExistingEmail(email) {
    let matches = await ExternalUsers.findByEmail(email);
    
    if (matches.length > 0 && matches[0][partyIdProp] !== employee.id) {
      setError(
        "This email address is already in use, please contact your Admin to create a new invitation."
      );
      setValidEmail(false);
      setSaving(false);
      return false;
    }
    else {
      return true;
    }
  }

  // Handles sending a new invitation.
  async function handleInvitation() {
    setSaving(true);

    try {
      let sendInvite = await checkExistingEmail(email);
      if (sendInvite) {
        await PartyHelpers.invite(employee, email, subject, message);
        onInvite(`An invitation has been sent to ${email}.`);
        handleClose();
      }
    } catch (err) {
      setError(`An error occurred when inviting ${employee.name}.`);
    }

    setSaving(false);
  }

  // Re-sends an invitation to an alumni.
  async function handleResendInvitation(partyId, name) {
    setSaving(true);

    try {
      let sendInvite = await checkExistingEmail(email);
      if (sendInvite) {
        await ExternalInvitations.invite(partyId, name, email, subject, message);
        onInvite(`A new invitation has been sent to ${email}.`);
        handleClose();
      }
    } catch (err) {
      setError(`An error occurred when re-inviting ${employee.name}.`);
    }

    setSaving(false);
  }

  return {
    error,
    warning,
    loading,
    handleResultSelect,
    handleSearchChange,
    results,
    value,
    resultRenderer,
    handleEmailChange,
    subject,
    message,
    handleClose,
    handleInvitation,
    handleResendInvitation,
    open,
    setOpen,
    valid,
    saving,
    getAlumniParty,
    handleSubjectChange,
    handleMessageChange,
  };
};

export const InviteModal = ({
  onInvite,
  isResendingInvite,
  partyId,
  name,
  alumniEmail,
}) => {
  const {
    error,
    warning,
    loading,
    handleResultSelect,
    handleSearchChange,
    results,
    value,
    resultRenderer,
    handleEmailChange,
    subject,
    message,
    handleClose,
    handleInvitation,
    handleResendInvitation,
    open,
    setOpen,
    valid,
    saving,
    getAlumniParty,
    handleSubjectChange,
    handleMessageChange,
  } = useInvite(onInvite);

  return (
    <Modal
      open={open}
      trigger={
        isResendingInvite ? (
          <Button
            onClick={() => {
              setOpen(true);
              getAlumniParty(partyId, alumniEmail);
            }}
            content="Invite"
            icon="refresh"
            labelPosition="right"
          />
        ) : (
          <Button
            onClick={() => {
              setOpen(true);
            }}
            primary
            content="Invite"
          />
        )
      }
    >
      <Modal.Header>
        {!isResendingInvite
          ? "Who would you like to invite?"
          : `Would you like to send ${name} another invitation?`}
      </Modal.Header>
      <Modal.Content>
        <Message error hidden={!error} content={error} />
        <Message warning hidden={!warning} content={warning} />
        <Form>
          {!isResendingInvite ? (
            <Form.Field required>
              <label>Employee</label>
              <Search
                placeholder="Find an employee"
                loading={loading}
                onResultSelect={handleResultSelect}
                onSearchChange={debounce(handleSearchChange, 500, {
                  leading: true,
                })}
                results={results}
                resultRenderer={resultRenderer}
                value={value}
              />
            </Form.Field>
          ) : (
            <Form.Input readOnly label="Employee">
              {name}
            </Form.Input>
          )}
          {!isResendingInvite ? (
            <EmailInput onChange={handleEmailChange} required />
          ) : (
            <Form.Input readOnly label="Email Address">
              {alumniEmail}
            </Form.Input>
          )}

          <SubjectInput
            onChange={handleSubjectChange}
            value={subject}
            required
          />
          <MessageInput
            onChange={handleMessageChange}
            value={message}
            required
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          positive
          disabled={!valid}
          onClick={() => {
            isResendingInvite
              ? handleResendInvitation(partyId, name)
              : handleInvitation();
          }}
          loading={saving || loading}
          content="Submit"
        />
      </Modal.Actions>
    </Modal>
  );
};
