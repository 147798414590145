import React from "react";
import {
  Grid,
  Segment,
  Header,
  Button,
  Form,
  Message,
  Input,
  Icon,
  Label
} from "semantic-ui-react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { richTextFromMarkdown } from '@contentful/rich-text-from-markdown';
import ScrollAnimation from "react-animate-on-scroll";

export class CustomCalendar extends React.Component {
  render() {
    return(
      <div className="eventCalenderTimeField">
        <DatePicker
          onChange={(date) => this.props.onChange(date)} 
          showTimeSelect
          selected={this.props.selected}
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="time"
          dateFormat="MMMM d, yyyy h:mm aa"
          className="date-time"
        />
      </div>
    )
  }
}

export default class ShareForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      error: "",
      eventPhoto: ""
    }
    this.fieldOneInput = React.createRef();
    this.fieldTwoInput = React.createRef();
    this.fieldThreeInput = React.createRef();
    this.applicationLinkInput = React.createRef();
    this.description = React.createRef();
  }

  handleChange = input => async event => {
    let val = event.target.value;

    if(event.target.type === "textarea") {
      val = await richTextFromMarkdown(val);
    }
    
    this.setState({ [input]: val });
  };
  
  handleDayChange = date => {
    this.setState({ when: date });
  };

  handleImageSelected = (e) => {
    e.preventDefault();
    this.setState({ eventPhoto :  e.target.files[0] });
  };
  
  // Clears any messages from previous submission.  Fires even if form is not valid.
  handleSubmitClick = () => {
    this.setState({
        error: "",
        success: false
    });
  };
  
  // Only fires if form is valid.  Calls handler from parent.
  handleOnSubmit = async () => {
    this.setState({loading: true});
    try {
      await this.props.handleSubmit(this.state);
      this.setState({success: true, loading: false, error: "", when: "", eventPhoto: "" });
      //reset form field values
      const resetFieldOne = this.fieldOneInput && this.fieldOneInput.current;
      const resetFieldTwo = this.fieldTwoInput && this.fieldTwoInput.current;
      const resetFieldThree = this.fieldThreeInput && this.fieldThreeInput.current;
      const resetDescription = this.description && this.description.current;
      const resetApplicationLink = this.applicationLinkInput && this.applicationLinkInput.current;
      
      if (resetFieldOne) {
        resetFieldOne.value = "";
      }
      if (resetFieldTwo) {
        resetFieldTwo.value = "";
      }
      if (resetFieldThree) {
        resetFieldThree.value = "";
      }
      if (resetDescription) {
        resetDescription.value = "";
      }
      if (resetApplicationLink) {
        resetApplicationLink.value = "";
      }
    }
    catch (err) {
      console.log(err);
      this.setState({success: false, loading: false, error: "Sorry, there was a error posting your " + this.props.formName });
    }
  };

  render() {
    const {
      className,
      formName,
      header,
      subHeader,
      labelOne,
      fieldOne,
      labelTwo,
      fieldTwo,
      labelThree,
      fieldThree,
      animationDuration,
      animationDelay
    } = this.props;
    const {
      error,
      success,
      loading,
      eventPhoto
     } = this.state;
    return (
      <Grid.Column computer={8} tablet={16}>
        <ScrollAnimation animateIn="fadeIn" duration={animationDuration} delay={animationDelay} animateOnce={true} offset={1200}>
          <div className={"form-container " + className}>
            <Header as="h1" className="share-header" >
              {header}
              <Header.Subheader className="sub-header-one">
                Please fill in the sections below then submit for approval.
              </Header.Subheader>
              <Header.Subheader>
                {subHeader}
              </Header.Subheader>
            </Header>
            <Form size="small" loading={loading} success={success} error={!!error} onSubmit={this.handleOnSubmit}>
              <Segment stacked className="form-fields">
                {!!fieldOne && (
                  <Form.Field>
                    <label>{labelOne}</label>
                    <input id={fieldOne} onChange={this.handleChange(fieldOne)} className={this.props.className} ref={this.fieldOneInput} required></input>
                  </Form.Field>
                  
                )}
                {!!fieldTwo && (
                  <Form.Field className={this.props.className}>
                    <label>{labelTwo}</label>
                    <input id={fieldTwo} onChange={this.handleChange(fieldTwo)} className={this.props.className} ref={this.fieldTwoInput} required></input>
                  </Form.Field>
                )}
                {!!fieldThree && (
                  <Form.Field className={this.props.className}>
                    <label>{labelThree}</label>
                    <input id={fieldThree} onChange={this.handleChange(fieldThree)} className={this.props.className} ref={this.fieldThreeInput} required></input>
                  </Form.Field>
                )}
                {formName === "event" && (
                  <Form.Field 
                    label='Date and Time' 
                    control={CustomCalendar}
                    onChange={this.handleDayChange} 
                    showTimeSelect
                    selected={this.state.when}
                    className="date-time-picker" 
                  />
                )}
                {formName !== "job posting" && (
                  <Form.Field className={this.props.className}>
                    <label>Description</label>
                    <textarea id="description" onChange={this.handleChange("description")} ref={this.description} name="description" required></textarea>
                  </Form.Field>
                )}
                {formName === "job posting" && (
                  <Form.Group widths="equal">
                    <Form.Field className={this.props.className}>
                      <label>Contact e-mail or link to application</label>
                      <input id="applicationLink" onChange={this.handleChange("applicationLink")} ref={this.applicationLinkInput} ></input>
                    </Form.Field>
                  </Form.Group>
                )}
                {formName === "event" && (
                  <Form.Group widths="equal">
                    <Form.Field className="inline-upload">
                      <label>Upload image or flyer of event</label>
                      <Input labelPosition='left'  placeholder="upload an image" value={eventPhoto && eventPhoto.name}>
                        <Label as="label" basic htmlFor="eventPhoto">
                          <Icon name='upload' size='large' className="upload-icon"/>
                        </Label>
                        <input />
                      </Input>
                      <Input type='file' id="eventPhoto" onChange={this.handleImageSelected} style={{visibility: "hidden", display: "none"}} accept="image/*"/>
                    </Form.Field>
                  </Form.Group>
                )}
                <Message
                  success
                  header='Submission Received'
                  content="Thank you for your contribution to your network, we’re reviewing your submission and you should see it added to the site in a few days."
                />
                <Message
                  error
                  header={!!error ? error : 'Form Not Submitted'}
                />
                <div>
                  <Button
                    className="profile-buttons"
                    size="small"
                    type="submit"
                    onClick={(e) => this.handleSubmitClick()}
                  >
                    SUBMIT FOR POSTING
                  </Button>
                </div>
                
              </Segment>
            </Form>
          </div>
        </ScrollAnimation>

      </Grid.Column> 
    )
  }
}