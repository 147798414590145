import React, { useState, useEffect } from "react";
import FileUpload from "./FileUpload";
import { map } from "lodash";
const AdditionalPhotos = ({
  handleAdditionalImagesDescription,
  additionalPhotos,
  handleRemove,
  additionalImagesDescription,
  selectedImages,
  selectedImage,
  selectedImageName,
  selectedImageType,
  addtionalLastPhotoIsCropped,
  croppedImages
}) => {
  const [image, setImage] = useState(selectedImage || null);
  const [imageName, setImageName] = useState(selectedImageName || null);
  const [imageType, setImageType] = useState(selectedImageType || null);
  const deletePhoto = (index) => handleRemove(index);
  useEffect(() => {
    setImage(selectedImage);
    setImageName(selectedImageName);
    setImageType(selectedImageType);
  }, [additionalPhotos, selectedImage, selectedImageName, selectedImageType]);
  
  return (
    <>
      {map(croppedImages, (photo, i) => {
        return (
          <div style={{ paddingTop: 15, textAlign:"left", margin: "0 0 1em" }} key={i}>
            <FileUpload
              type="additionalPhotos"
              imageSrc={photo}
              allowDelete={true}
              preventEdit={true}
              index={i}
              deletePhoto={deletePhoto}
            />
            <label style={{ fontSize: "13px", marginBottom: "4px" }}>Add Image Caption</label>
            <input
              style={{ border: "1px solid #323e48", borderRadius: 0 }}
              data-index={i}
              id={`description${i}`}
              name={`description${i}`}
              label="Add Image Caption"
              onChange={handleAdditionalImagesDescription}
              value={additionalImagesDescription[`description${i}`] || ""}
            />
          </div>
        );
      })}
      { image &&
        <div style={{ paddingTop: 15, textAlign:"left", margin: "0 0 1em" }}>
          <FileUpload
            type="additionalPhotos"
            imageSrc={image}
            allowDelete={true}
            preventEdit={true}
            index={croppedImages.length}
            deletePhoto={deletePhoto}
            selectedImage={ !addtionalLastPhotoIsCropped ? image : null}
            selectedImageName={imageName}
            selectedImageType={imageType}
            selectedImages={selectedImages}
          />
          <label style={{ fontSize: "13px", marginBottom: "4px" }}>Add Image Caption</label>
          <input
            style={{ border: "1px solid #323e48", borderRadius: 0 }}
            data-index={croppedImages.length}
            id={`description${croppedImages.length}`}
            name={`description${croppedImages.length}`}
            label="Add Image Caption"
            onChange={handleAdditionalImagesDescription}
            value={additionalImagesDescription[`description${croppedImages.length}`] || ""}
          />
        </div>
      }
    </>
  );
};

export default AdditionalPhotos;
