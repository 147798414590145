import PropTypes from "prop-types";
import React, { Component  } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Grid,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility
} from "semantic-ui-react";
import SettingsMenu from "./SettingsMenu";
import "../styles/Navigation.css";
import { ReactComponent as AlumniLogo } from "../images/alumniLogo.svg";
import { ReactComponent as HorizontalAlumniLogo } from "../images/alumniHorizontalLogo.svg";
import { ReactComponent as HorizontalWhiteAlumniLogo } from "../images/alumniHorizontalWhiteLogo.svg";
import GlobalSearchBar from "../components/GlobalSearchBar";
const getWidth = () => {
  const isSSR = typeof window === "undefined";

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

const TopMenu = props => {
  const location = window.location.pathname.replace(/^\/+/, '');

  return (
    <Menu
      fixed={props.fixed ? "top" : null}
      pointing={!props.fixed}
      secondary={!props.fixed}
      size="large"
      style={{
        borderBottom: 0,
        borderTop: 0
      }}
    >
      <Menu.Item className="inner-nav-logo" style={{display: props.fixed ? "flex": "none"}}>
          <HorizontalAlumniLogo className="inner-scroll-logo alumni-red" />
      </Menu.Item>
      <Menu.Item
        as={Link}
        name="home"
        active={location === ""}
        to="/"
      >
        HOME
      </Menu.Item>
      <Menu.Item
        as={Link}
        name="network"
        active={location === "network"}
        to="/network"
      >
        NETWORK
      </Menu.Item>
      <Menu.Item
        as={Link}
        name="news"
        active={location === "news"}
        to="/news"
      >
        NEWS
      </Menu.Item>
      <Menu.Item
        as={Link}
        name="events"
        active={location === "events"}
        to="/events"
      >
        EVENTS
      </Menu.Item>
      <Menu.Item
        as={Link}
        name="careers"
        active={location === "careers"}
        to="/careers"
      >
        OPPORTUNITIES
      </Menu.Item>
      <Menu.Item
        as={Link}
        name="share"
        active={location === "share"}
        to="/share"
        className="share"
      >
        SHARE
      </Menu.Item>
      <GlobalSearchBar
       className={props.fixed ? "search-menu-item fixed-search-width desktop-global-search" : "search-menu-item desktop-global-search"}
       />
    </Menu>
  );
};

export class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() { 
    const { children } = this.props;
    const { fixed, itsactive } = this.state;

    return (
      <Responsive
        className="nav-page"
        getWidth={getWidth}
        minWidth="992"
      >
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Container>
            <Grid
              stackable
              padded="vertically"
              className="desktop-navigation"
              verticalAlign='middle'
            >
              <Grid.Row>
                <Grid.Column width={2}>
                  <a href="/">
                    <div className="alumni-logo-desktop">
                      <AlumniLogo className="alumni-red"/>
                    </div>
                  </a>
                </Grid.Column>
                <Grid.Column width={14}>
                <Grid className="navigation-menus" container columns={1}>
                  <Grid.Column
                    className="desktop-social-settings-menu"
                    floated="right"
                  >
                    <Menu icon>
                      <Menu.Item
                        className="social-menu-item"
                        name="linkedin"
                        href="https://www.linkedin.com/company/creative-artists-agency/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="linkedin" />
                      </Menu.Item>
                      <Menu.Item
                        className="social-menu-item"
                        name="instagram"
                        href="https://www.instagram.com/creativeartistsagency/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="instagram" />
                      </Menu.Item>
                      <Menu.Item
                        className="facebook-menu-item"
                        name="facebook f"
                        href="https://www.facebook.com/CreativeArtistsAgency/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="facebook f" />
                      </Menu.Item>
                      <SettingsMenu />
                    </Menu>
                  </Grid.Column>
                  <hr className="navigation-menu-divider"></hr>
                  <Grid.Column className="site-menu" floated="right">
                    <TopMenu
                      fixed={fixed ? "top" : null}
                      pointing={!fixed}
                      secondary={!fixed}
                      activeMenuItem={this.activeMenuItem}
                      itsactive={itsactive}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
              </Grid.Row>


            </Grid>
          </Container>
        </Visibility>
        {children}
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node
};

const SideMenu = props => {
  return (
    <Sidebar
      as={Menu}
      animation="push"
      inverted
      direction="right"
      onHide={props.handleSidebarHide}
      vertical
      visible={props.sidebarOpened}
    >
      <Menu.Item as={Link} to="/">
        HOME
      </Menu.Item>
      <Menu.Item as={Link} to="/network">
        NETWORK
      </Menu.Item>
      <Menu.Item as={Link} to="/news">
        NEWS
      </Menu.Item>
      <Menu.Item as={Link} to="/events">
        EVENTS
      </Menu.Item>
      <Menu.Item as={Link} to="/careers">
        OPPORTUNITIES
      </Menu.Item>
      <Menu.Item as={Link} to="/share">
        SHARE
      </Menu.Item><Menu.Item as={Link} to="/editProfile">
        PROFILE
      </Menu.Item>
      <Menu.Item onClick={() => window.location.assign("/logout")}>
        LOGOUT
      </Menu.Item>
      <Menu.Item as={Link} to="/contact">
        ABOUT
      </Menu.Item>
      <Menu.Item as={Link} to="/privacyPolicy">
        PRIVACY POLICY
      </Menu.Item>
      <Menu.Item as={Link} to="/termsOfUse">
        TERMS OF USE
      </Menu.Item>
      <GlobalSearchBar
        className="mobile-global-search-bar"
       />
    </Sidebar>
  );
};

export class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth="991"
      >
        <SideMenu
          sidebarOpened={sidebarOpened}
          handleSidebarHide={this.handleSidebarHide}
        />
        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign="center"
            style={{
              padding: "0 0 5px",
              background: "#bc1f2f",
              marginBottom: "13px"
            }}
            vertical
          >
            <div style={{ marginLeft: "11px" }}>
              <Menu inverted pointing secondary size="large">
                <Menu.Item position="left" style={{paddingTop: '16px', paddingBottom: '10px'}}>
                  <a href="/">
                    <div style={{width:'200px'}}>
                      <HorizontalWhiteAlumniLogo />
                    </div>
                  </a>
                </Menu.Item>
                <Menu.Item onClick={this.handleToggle} position="right" style={{paddingTop: '5px', paddingBottom: '0'}}>
                  <Icon name="sidebar"/>
                </Menu.Item>
              </Menu>
            </div>
          </Segment>
          {children}
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node
};

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>
      <Container>{children}</Container>
    </DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
);

export default ResponsiveContainer;
