import * as Contributions from "../api/contributions";
import * as CFHelper from "../util/contentful";
import { getUser } from "./user";

export function createContribution(contribution) {  
  return async (dispatch, getState) => {
    CFHelper.addContributorInfo(contribution, getUser(getState()));
    await Contributions.create(contribution);
  };
}
