import React from "react";
import { Route, Redirect } from "react-router-dom";
import store from "../redux/store";
import { getUser } from "../redux/user";

const AdminRoute = ({ component: Component, ...rest }) => {
  const user = getUser(store.getState());
  return (
    <Route
      {...rest}
      render={props =>
        user.isAdmin === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/unauthorized" />
        )
      }
    />
  );
};

export default AdminRoute;
