import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Grid,
  Segment,
  Header
} from "semantic-ui-react";
import {get } from "lodash";
import { getJobs } from "../redux/jobs";
import SearchBar from "../components/SearchBar";
import DropdownFilter from "../components/DropdownFilter";
import Delayed from "../components/Delayed";
import ScrollAnimation from "react-animate-on-scroll";

function convertJobsForSearch (jobs) {
  return jobs.map(                    
    item =>
      (item = {
        title: get(item, "title", ""),
        description: get(item, "company", ""),
        id: get(item, "id", "")
      })
  )
}

function JobSummary(props) {
  const { job } = props;
  const dateJobPosted = get(job, "datePosted", null);
  let link = get(job, "applicationLink");
  if(link && link.toLowerCase().indexOf("http") !== 0) {
    link = "mailto:" + link;
  }

  return (
      <Grid
        columns="equal"
        stackable
        textAlign="left"
        verticalAlign="middle"
        className="careers-posting"
      >
        <Grid.Column className="careers-image-container">
          <div
            className="careers-image-tile"
            style={{
              backgroundImage: `url(${get(job, "companyLogo.url", "")})`
            }}
          >
          </div>
        </Grid.Column>
        <Grid.Column width={8}>
          <div className="jobs-detail">
            <h3>{get(job, "title", "")}</h3>
            <p>{get(job, "company", "")}<br/> {get(job, "location", "")}
              <br/>
              {!!dateJobPosted && (
                new Intl.DateTimeFormat("en-US", {year: "numeric", month:"long", day: "numeric"}).format(new Date(dateJobPosted))
              )}
            </p>
          </div>
        </Grid.Column>
        <Grid.Column >
          <div className="careers-button-container">
            {link && (
              <Button
                className="careers-button"
                as="a"
                size="medium"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                VIEW
              </Button>
            )}
          </div>
        </Grid.Column>
      </Grid>
  );
}

class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredCareers: props.jobs
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.jobs !== prevProps.jobs) {
      this.setState({ filteredCareers: this.props.jobs })
    }
  }

  render() {
    const { jobs } = this.props;
    const { filteredCareers } =  this.state;
    const dropdownValues = jobs.filter(j => j.city).map(job => ({key:job.id, text:job.city.toUpperCase(), value:job.city.toUpperCase()}));


      return (
        <Container className="careers-page">
          <Segment vertical className="careers-search-segment">
            <Grid
              doubling
              stackable
              columns="equal"
            >
              <Grid.Column width={8}>
                <SearchBar
                  name="careers"
                  source={convertJobsForSearch(jobs)}
                  placeholder="SEARCH OPPORTUNITIES"
                  history={this.props.history}
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <DropdownFilter
                setFilter={(filteredItems) => {this.setState({ filteredCareers: filteredItems})}}
                source={jobs}
                className="events-dropdown"
                name="careersFilter"
                placeholder="Select A City"
                dropDownOptions={dropdownValues}
                filterBy="city"
               />
              </Grid.Column>
            </Grid>
            <Grid
              doubling
              stackable
              columns={2}
              divided='vertically'
            >
              {!(Array.isArray(filteredCareers) && filteredCareers.length) &&
                (
                  <Delayed wait={1000}>
                    <div className="pages">
                      <Container text style={{ marginTop: "7em" }}>
                        <Header as="h1">No Results for selected city.</Header>
                      </Container>
                    </div>
                  </Delayed>
                )
              }
              {filteredCareers.map((job, i) => (
                <Grid.Column 
                  key={job.id}>
                    <ScrollAnimation 
                      animateIn="fadeIn" 
                      delay={i * 1} 
                      animateOnce={true} 
                      duration={0.5}
                      offset={1200}
                    >
                      <JobSummary
                        onClick={obj => this.onClick(obj)}
                        job={job}
                      />
                      <hr className="careers-page-dividers"></hr>
                    </ScrollAnimation>

                </Grid.Column>
              ))}
            </Grid>
            
        </Segment>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    jobs: getJobs(state)
  };
}

export default connect(mapStateToProps)(Careers);
