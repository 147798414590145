import React from "react";
import { Container, Grid, Segment, Header } from "semantic-ui-react";

import JobPosting from "../components/share/JobPosting";
import EventPosting from "../components/share/EventPosting";
import AccoladePosting from "../components/share/AccoladePosting";
import ContributionPosting from "../components/share/ContributionPosting";
import ScrollAnimation from "react-animate-on-scroll";

export default class Share extends React.Component {
  render() {
    return (
      <Container className="share-page">
        <Segment vertical>
          <ScrollAnimation animateIn="fadeIn" duration={0.5} animateOnce={true} offset={1200}>
            <Header as="h3">CONTRIBUTE TO YOUR ALUMNI NETWORK</Header>
            <p className="share-page-subheaders sub-one">
              We want to know all about you including updates in your career,
              career opportunities you may know about and relevant job postings.
            </p>
            <p className="share-page-subheaders sub-two">Please add the appropriate links below.</p>
          </ScrollAnimation>
          <Grid stackable className="share-forms-section">
            <JobPosting />
            <EventPosting />
            <AccoladePosting />
            <ContributionPosting />
          </Grid>
        </Segment>
      </Container>
    );
  }
}
