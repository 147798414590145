import React from 'react';
import { getVideo, getFilteredMedia } from '../redux/media';
import { useSelector } from "react-redux";
import Thumbnail from '../components/Thumbnail';
import ReactJWPlayer from 'react-jw-player';
import { Placeholder, Divider } from 'semantic-ui-react';

const Media = () => {
  const media = useSelector(getFilteredMedia);
  const video = useSelector(getVideo);
  const series = video?.additionalData?.find(data => data?.name === "series")

  return (
    <div>
      <div style={{ marginBottom: 100 }}>
        <h4>{series?.value}</h4>
        {!video?.uri ? (
          <Placeholder style={{ maxWidth: 'unset' }}>
            <Placeholder.Image style={{ width: '100%', paddingBottom: '56.25%'}}/>
          </Placeholder>
        ) : (
          <ReactJWPlayer
            playerId='u3m3wDjBxpcyoteOd'
            playerScript='https://content.jwplatform.com/libraries/6D29vijP.js'
            file={video.uri}
            image={video.imgUri}
          />
        )}
        <h3>{video?.name}</h3>
        <p>{video?.description}</p>
      </div>
      <Divider />
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignContent: 'space-between'
      }}>
        {media?.map(video => (
          <Thumbnail
            key={video.id}
            video={video}
          />
        ))}
      </div>
      <Divider />
    </div>
  )
}


export default Media
