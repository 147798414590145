import * as CFHelper from "../util/contentful";
import { map, get } from "lodash";
import axios from "axios";

function fixUpItem (item) {
  return {
    ...item.fields,
    id: get(item, "sys.id"),
    image: CFHelper.getImageURL(item.fields.image),
    logo: CFHelper.getImageURL(item.fields.logo)
  };
}

export async function all() {
  return axios
    .get(`/api/alumni/news`)
    .then(response => map(response.data.items, fixUpItem));
}