import React from 'react';
import { Search } from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import escapeRegExp from 'lodash/escapeRegExp';

const initialState = { isLoading: false, results: [], value: '' };

export default class SearchBar extends React.Component {
  state = initialState;

  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.title });
    let name;
    switch (this.props.name) {
      case 'network':
        name = 'profile';
        break;
      case 'news':
        name = 'article';
        break;
      case 'careers':
        name = 'career';
        break;
      case 'events':
        name = 'event';
        break;
      default:
        console.log('result.id', result.id)
    }
    this.props.history.push({
      pathname: `/${name}/${result.id}`
    })
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });

    setTimeout(() => {
      if (this.state.value.length < 1) return this.setState(initialState);

      const re = new RegExp(escapeRegExp(this.state.value), 'i');
      const isMatch = function(result) {
        return re.test(result.title);
      }

      this.setState({
        isLoading: false,
        results: filter(this.props.source, isMatch),
      })
    }, 300);
  }

  render() {
    const { isLoading, value, results } = this.state;    
    return (
      <div>
        <Search
          aligned='right'
          loading={isLoading}
          onResultSelect={this.handleResultSelect}
          onSearchChange={debounce(this.handleSearchChange, 500, {
            leading: true,
          })}
          results={results}
          value={value}
          placeholder={this.props.placeholder}
          className="search-input content-type-search-bar"
        />
      </div>
    )
  }
}