import React from "react";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Label, Menu, Dropdown } from "semantic-ui-react";
import { getFirstName, getProfilePicture, getProfileId } from "../redux/user";

export const SettingsMenu = props => {
  const history = useHistory();
  const handleEditProfile = () => {
    history.push(`/profile/${props.profileId}`);
  };
  return (
    <Menu.Item
      className="setting-menu-item"
      name="setting"
    >
      <Label image>
        {props.profilePicture ? (
          <div
          className="settings-profile-image"
          style={{ backgroundImage: `url(${props.profilePicture})` }}
        ></div>
        ) : (
          ""
        )}
      </Label>

      <Dropdown item text={props.firstName ? `Hi, ${props.firstName} ` : " "} icon="setting">
        <Dropdown.Menu>
          <Dropdown.Item onClick={handleEditProfile}>PROFILE</Dropdown.Item>
          <Dropdown.Item onClick={() => window.location.assign("/logout")}>LOGOUT</Dropdown.Item>
          <Dropdown.Item as={Link} to="/contact">ABOUT</Dropdown.Item>
          <Dropdown.Item as={Link} to="/privacyPolicy">PRIVACY POLICY</Dropdown.Item>
          <Dropdown.Item as={Link} to="/termsOfUse">TERMS OF USE</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  );
};

function mapStateToProps(state) {
  return {
    firstName: getFirstName(state),
    profilePicture: getProfilePicture(state),
    profileId: getProfileId(state)
  };
}

export default connect(mapStateToProps)(SettingsMenu);
