import React from "react";
import { connect } from "react-redux";
import { Container, Grid, Segment, Divider, Button } from "semantic-ui-react";
import get from "lodash/get";
import { getNews } from "../redux/news";
import ScrollAnimation from "react-animate-on-scroll";

function NewsArticleList(props) {
  const { article } = props;
  return (
    <Container className="news-page">
      <Divider section className="news-segment-divider" />
      <Segment vertical className="news-segment">
        <Grid
          columns="equal"
          stackable
          verticalAlign="middle"
        >
          <Grid.Row>
            <Grid.Column textAlign='left' className="news-image-container"mobile={16} tablet={4} computer={3}>
              <div
                className="news-image-tile"
                style={{
                  backgroundImage: `url(${article.logo.url})`
                }}
              ></div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={7} computer={9}>
              <div className="news-detail" >
                <h3>{get(article, "title", "")}</h3>
                <p>{new Intl.DateTimeFormat("en-US", {month: "long", year: "numeric", day: "numeric"}).format(new Date(get(article, "date", "")))}</p>
                <div className="story-content">
                  {get(article, "summary", "")}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column  textAlign='right' mobile={16} tablet={5} computer={4}>
              <div className="read-original-story-container">
                <Button
                  className="read-original-story-button"
                  as="a"
                  size="medium"
                  href={get(article, "link", "#")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  READ ORIGINAL STORY
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  );
}


class News extends React.Component {

  render() {
    const {news} = this.props;
    return (
      news.map((article, i) => (
        <ScrollAnimation 
          key={i} 
          animateIn="fadeIn" 
          animateOnce={true} 
          duration={0.5}
          delay={i * 1}
          offset={1200}
        >
          <NewsArticleList
            id={i}
            article={article}
          />
        </ScrollAnimation>

      ))
    )
  }
}

function mapStateToProps(state) {
  return {
    news: getNews(state)
  };
}

export default connect(mapStateToProps)(News);
