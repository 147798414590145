import React, { useState } from "react";
import { Form, Button, Icon, Label } from "semantic-ui-react";
import { isEmpty, map } from "lodash";

const options = [
  { key: "twitter", text: "Twitter", value: "twitter" },
  { key: "facebook", text: "Facebook", value: "facebook" },
  { key: "instagram", text: "Instagram", value: "instagram" },
  { key: "linkedin", text: "LinkedIn", value: "linkedin" },
  { key: "youtube", text: "YouTube", value: "youtube" },
  { key: "pinterest", text: "Pinterest", value: "pinterest" },
  { key: "snapchat", text: "Snapchat", value: "snapchat" },
];

const SocialLinks = ({ links, handleSocialLinksButton }) => {
  const [socialLink, setSocialLink] = useState("");
  const [socialMediaOption, setSocialMediaOption] = useState();
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState();

  const handleChange = (e) => setSocialLink(e.target.value);

  const handleSubmit = () => {
    const protocol = /(http|https)/g;
    let currentLink = socialLink;

    if (dirty && (socialLink || "").toLowerCase().indexOf(socialMediaOption) < 0) {
      setError({
        content: `Please enter a valid ${socialMediaOption} link`,
        pointing: "above",
      });
    } else {
      currentLink = !protocol.test(currentLink)
        ? `https://${currentLink}`
        : currentLink;
      handleSocialLinksButton(currentLink);
      setSocialLink("");
      setError(undefined);
      setSocialMediaOption();
    }
  };

  const removeSocialLink = (link) => handleSocialLinksButton(link, true);

  return (
    <>
      <Form.Group
        unstackable
        width={3}
        style={{ paddingTop: 23 }}
      >
        <Form.Select
          className="social-select"
          placeholder="SELECT"
          options={options}
          onChange={(e, selected) => setSocialMediaOption(selected.value)}
        />
        <Form.Input
          width={11}
          placeholder="http://www.example.com/twitter"
          onChange={handleChange}
          value={socialLink}
          onBlur={() => {
            if (socialLink.trim().length > 0) {
              setDirty(true);
            } else {
              setDirty(false);
            }
          }}
          error={error}
        />
        <div>
          <Button
            icon={<Icon name="plus" />}
            style={{ background: "none", color: "#bc1f2f" }}
            onClick={handleSubmit}
            disabled={!dirty}
          />
        </div>
      </Form.Group>
      {!isEmpty(links) && (
        <Label.Group className="podcasts" style={{ paddingBottom: 15 }}>
          {map(links, (link, i) => (
            <div key={i} style={{ paddingBottom: 10 }}>
              <Label style={{ color: "#fff", backgroundColor: "#ababab", borderRadius: 0 }}>
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white", opacity: "1" }}
                >
                  {link}{" "}
                </a>
                <Icon name="close" onClick={() => removeSocialLink(link)} />
              </Label>
            </div>
          ))}
        </Label.Group>
      )}
    </>
  );
};

export default SocialLinks;
