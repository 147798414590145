import React, { useEffect } from "react";
import { Container, Segment, Header } from "semantic-ui-react";

export default function PrivacyPolicy() {

  useEffect(() => {
    //interval is needed to check when OneTrust api become available via Window
    let interval = setInterval(()=>{
      if(window.OneTrust){
        window.OneTrust.NoticeApi.Initialized.then(function () {
          window.OneTrust.NoticeApi.LoadNotices(["https://privacyportal-cdn.onetrust.com/722df920-f42e-4d91-b843-6306f9a6a5a0/privacy-notices/15c6bc6c-4e54-4f1d-a8c5-ed61779a4339.json"]);
        });
        clearInterval(interval);
      }
    }, 200);
  }, [])

  return (
    <Container className="privacy-policy-page">
      <Segment vertical>
        <Header as="h3">PRIVACY POLICY</Header>
        <div className="privacy-policy-page-subheaders">
        <div id="otnotice-15c6bc6c-4e54-4f1d-a8c5-ed61779a4339" className="otnotice"></div>
        </div>
      </Segment>
    </Container>
  );
}
