import axios from "axios";
console.log(process.env.NODE_ENV)
const application =
  process.env.NODE_ENV === "development"
    ? "alumni.caadev.com"
    : window.location.host;

export async function invite(partyId, name, email, subject, message) {
  const payload = {
    partyId: partyId,
    emailAddress: email,
    name: name,
    application,
    resources: ["alumni"],
    subject: subject,
    message: `<p>${message}</p>`, // TODO: remove paragraph element when using rich text editor
    authorization: {
      scope: [
        {
          name: "SEC-ALUMNI-EDITORS",
          role: "collaborator",
          type: "claim"
        }
      ],
      team: []
    },
    notifyMessage: true,
    senderEmail: "MSain@caa.com",
    senderDisplayname: "Melia Sain",
    additionalRecipients: [
      {
        "email": "MSain@caa.com",
        "name": "Melia Sain",
        "type": 'bcc'
      },
      {
        "email": "CAAAlumni@caa.com",
        "name": "CAA Alumni",
        "type": 'bcc'
      }
    ]
  };

  return axios.post("/api/externalInvitations", payload).then(res => res.data);
}
