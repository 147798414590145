import axios from "axios";
export function get() {
  return axios.get('/api/mediaAlumni').then(res => res.data);
}

export function getUri(id) {
  return axios.get(`/api/media/${id}/uri?format=large`).then(res => res.data);
}

export function getVideo(id) {
  return axios.get(`/api/media/${id}/uri?format=raw&suffix=hlsv3`).then(res => res.data);
}