import { createSlice, createSelector } from "@reduxjs/toolkit";
import * as Media from "../api/media";

const initialState = {
  allMedia: [],
  video: {}
}
let media = createSlice({
  name: "media",
  initialState,
  reducers: {
    setMedia: (state, action) => {
      state.allMedia = action.payload
    },
    setVideo: (state, action) => {
      state.video = action.payload
    }
  }
})

export const { setMedia, setVideo, setVideoImage } = media.actions;

export function fetchVideo(mediaItem) {
  return async (dispatch) => {
    try {
      const video = await Media.getVideo(mediaItem.id)
      const tile = mediaItem.additionalData?.find(data => data?.name === "tile")
      const videoImage = await Media.getUri(tile.value)
      dispatch(setVideo({ ...video, imgUri: videoImage.uri }))
    } catch (err) {
      console.log('err:', err)
    }
  }
}

export function fetchMedia() {
  return async (dispatch) => {
    try {
      const media = await Media.get();
      if (media.length > 0) {
        await dispatch(fetchVideo(media[0]))
      }
      dispatch(setMedia(media));
    } catch (err) {
      console.log(err);
    }
  };
}

export const getMedia = createSelector(
  state => state.media.allMedia,
  media => media
)

export const getVideo = createSelector(
  state => state.media.video,
  getMedia,
  (video, media) => {
    const mediaVid = media.find(vid => vid.id === video.id)
    return { ...mediaVid, ...video }
  }
)

export const getFilteredMedia = createSelector(
  getMedia,
  getVideo,
  (media, video) => {
    return media.filter(vid => vid.id !== video.id)
  }
)

export default media.reducer;
