import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appName: "alumni"
};

let user = createSlice({
  name: "app",
  initialState: initialState
});

export default user.reducer;
