import React from "react";
import { useDispatch } from "react-redux";
import ShareForms from './ShareForms';
import * as Contributions from "../../redux/contributions";

export default function ContributionPosting () {
  const dispatch = useDispatch();
  return (
    <ShareForms
      className="other-posting"
      header="OTHER"
      formName="contribution" 
      labelOne="Type of Contribution"
      fieldOne="type"
      handleSubmit={(data) => dispatch(Contributions.createContribution(data))}
      animationDuration={0.5}
      animationDelay={1}
    />
  );
}