import React from "react";
import { Container, Grid, Header, Segment, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { get } from "lodash";
import { getEvents } from "../redux/events";
import BackButton from "../components/BackButton";
import ScrollAnimation from "react-animate-on-scroll";

const DATE_OPTIONS = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric"
};
function Event (props) {
  const { match: { params } } = props;
  const eventId = get(params, "id");
  const { events } = props;
  const event = events.find((p) => p.id === eventId);

  return (
    <>
      {!event && (
        <div className="pages">
          <Container className="no-event-page">
            <Segment vertical>
              <Header as="h3">We are having trouble finding the event you selected</Header>
            </Segment>
          </Container>
        </div>
      )}
      {!!event && (<Container className="events-page">
        <Segment vertical className="news-segment">
          <Grid
            stackable
            textAlign="left"
            verticalAlign="middle"
            columns={2}
          >
            <Grid.Column width={16}>
              <Link to="/events">
                  <BackButton
                    label="BACK TO EVENTS"
                  />
                </Link>
            </Grid.Column>
          </Grid>
          <Grid className="event-details" stackable columns="equal">
            <Grid.Row>
              <Grid.Column className="news-article-image" textAlign='left' width={6}>
                <ScrollAnimation animateIn="fadeIn" duration={0.5} animateOnce={true} offset={1200}>
                  <div
                    className="news-article-image-tile"
                    style={{
                      backgroundImage: `url(${event.image.url})`
                    }}
                  ></div>
                </ScrollAnimation>
              </Grid.Column>
              <Grid.Column>
                <ScrollAnimation animateIn="fadeIn" duration={0.5} delay={2} animateOnce={true} offset={1200}>
                  <div className="event-information">
                    <h1>{event.title}</h1>
                    <Grid verticalAlign="bottom" columns={2}>
                      <Grid.Row>
                        <Grid.Column>
                          <p className="post-location">{event.location}<br/>
                            {new Intl.DateTimeFormat("en-US", DATE_OPTIONS).format(
                            new Date(event.when)
                          )}
                          </p>
                        </Grid.Column>
                        <Grid.Column>
                          {!!event.link && (<div className="event-page-button-container add-bottom-padding">
                            <Button
                              className="event-page-button"
                              as="a"
                              size="medium"
                              href={event.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Get Tickets
                            </Button>
                          </div>)}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                  <div className="section-divider">
                    <hr></hr>
                  </div>
                  <div className="event-description">
                    <h3>DESCRIPTION</h3>
                    <div>{documentToReactComponents(event.description)}</div>
                  </div>
                </ScrollAnimation>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
    </Container>
    )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    events: getEvents(state)
  };
}

export default connect(mapStateToProps)(Event);
