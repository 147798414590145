import React from "react";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";

export function Login() {
  return (
    <Grid.Column computer={8} tablet={16} className="form-height">
      <div className="form-container">
        <Header as="h1" className="login-header">
          GO TO MY NETWORK
          <Header.Subheader className="login-subheader">
            Welcome back! We're so glad you're here. <br/>
            Please login.
          </Header.Subheader>
        </Header>
        <Form size="small">
          <Segment stacked className="form-fields">
            <Button
              className="landing-buttons"
              size="small"
              href="/login?external=true"
            >
              LOGIN
            </Button>
          </Segment>
        </Form>
      </div>
    </Grid.Column>
  );
}
