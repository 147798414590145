import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { uniqBy } from 'lodash';

function YearFilter (props) {
  function handleYearDropdownChange(e, { value }) {
    props.setSelectedYear(Number(value));
  }

  return (
    <div>
      <Dropdown
        name="yearFilter"
        className={props.className}
        placeholder="Select Year"
        clearable
        selection
        onChange={handleYearDropdownChange}
        options={uniqBy(props.dropDownOptions, "value")}
        value={props.selectedYear}
        selectOnBlur={false}
      ></Dropdown>
    </div>
  )
}

export default YearFilter;