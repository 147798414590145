import React from "react";
import { Container, Header } from "semantic-ui-react";

export default function Unauthorized() {
  return (
    <div className="pages">
      <Container text style={{ marginTop: "7em" }}>
        <Header as="h1">You must be authorized to use alumni</Header>
        <p>
          Please contact CAA 
        </p>
      </Container>
    </div>
  );
}