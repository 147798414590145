import { map, get } from "lodash";
import { localizeFields, getImageURL, getImageURLs, getLinkedEntry } from "../util/contentful";
import axios from "axios";
let source = axios.CancelToken.source();

export function fixUpItem(item) {
  return {
    ...item.fields,
    id: get(item, "sys.id"),
    company: getLinkedEntry(get(item, "fields.company")),
    profilePicture: getImageURL(get(item, "fields.profilePicture")),
    petPhoto: getImageURLs(get(item, "fields.petPhoto")),
    additionalPhotos: getImageURLs(get(item, "fields.additionalPhotos"))
  };
}

export async function all({ limit, offset, status }) {
  let url = `/api/alumni/profiles?limit=${limit}&offset=${offset}`;
  if (status) {
    url += `&status=${status}`;
  }
  return axios
    .get(url)
    .then((response) => {
      let profiles = map(response.data.items, fixUpItem);
      let total = get(response.data, "total");
      return { profiles, total };
    });
}

export async function registered() {
  return axios
    .get(`/api/alumni/registeredProfiles`)
    .then((response) => map(response.data.items, fixUpItem));
}

export async function getProfile() {
  try {
    const profile = await axios
      .get(`/api/alumni/profile`)
      .then((res) => res.data);
    return profile;
  }
  catch (err) {
    console.log('error getting profile: ', err)
  }
}

export async function getByProfileId(profileId) {
  return axios
    .get(`/api/alumni/profile/${profileId}`)
    .then((response) => response.data);
}

export async function getByProfilePartyId(partyId) {
  return axios
    .get(`/api/alumni/profiles?partyId=${partyId}`)
    .then((response) => response.data);
}

export async function getByProfileSearchValue(searchValue) {
  source.cancel();
  source = axios.CancelToken.source();
  return axios
    .get(`/api/alumni:searchProfiles?terms=${searchValue}`, { cancelToken: source.token })
    .then((response) => map(response.data.items, fixUpItem));
}

export async function create(payload) {
  return axios
    .post(`/api/alumni/profile`, localizeFields(payload))
    .then((res) => res.data);
}

export async function update(id, payload, isUser) {
  const route = isUser ? `/api/alumni/profile` : `/api/alumni/profile/${id}?publish=true`
  return axios
    .put(route, localizeFields(payload))
    .then((res) => res.data);
}

export async function updateDraft(id, payload) {
  return axios
    .put(`/api/alumni/profile/${id}`, localizeFields(payload))
    .then((res) => res.data);
}
