import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin  } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

export { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

const appInsightsConnectionString = {
  production: "InstrumentationKey=c8d05b74-6d73-4017-b4a8-7ca72b5ca80f;IngestionEndpoint=https://westus-0.in.applicationinsights.azure.com/",
  default: "InstrumentationKey=97ef5a06-57a9-4e83-b844-d4e91c77720c;IngestionEndpoint=https://westus-0.in.applicationinsights.azure.com/"
};

export const insightsReactPlugin = new ReactPlugin();

export function initInsights(partyId, env) {
  const history = createBrowserHistory({ basename: '' });

  const appInsights = new ApplicationInsights({
    config: {
      connectionString: appInsightsConnectionString[env],
      accountId: partyId,
      extensions: [insightsReactPlugin],
      extensionConfig: {
        [insightsReactPlugin.identifier]: { history }
      }
    }
  });
  appInsights.loadAppInsights();

  return history;
}