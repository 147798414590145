import React, { useState } from "react";
import { Search } from "semantic-ui-react";
import useTypeahead from "../hooks/useTypeahead"

const AddressTypeahead = ({ currentLocation, handleLocation }) => {
  const [dirty, setDirty] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { loading, searchResults } = useTypeahead(searchText)

  const handleResultSelect = (e, { result }) => {
    handleLocation(result.title);
    setSearchText("");
    setDirty(false);
  };

  const handleChange = (e) => {
    setDirty(true);
    setSearchText(e.target.value);
  }


  const resultRenderer = ({ title }) => <div>{title}</div>;

  return (
    <>
      <label
        style={{
          textAlign: "left",
          color: "#bc1f2f",
          fontWeight: 300,
          fontSize: 13,
          display: "block",
          marginBottom: ".28571429rem"
        }}
      >
        Current Location (City, State) *
      </label>
      <Search
        className="address-typeahead"
        input={{ fluid: true, placeholder: "Enter a city" }}
        value={dirty ? searchText : currentLocation}
        minCharacters={2}
        loading={loading}
        results={searchResults}
        onResultSelect={handleResultSelect}
        onSearchChange={handleChange}
        resultRenderer={resultRenderer}
        onBlur={() => setDirty(false)}
      />
    </>
  );
};

export default AddressTypeahead;
