import * as Profiles from "../api/profiles";
import * as ExternalInvitations from "../api/externalInvitations";
import * as Parties from "../api/parties";
import { find, get } from "lodash";

export function getDisplayName(party) {
  return find(party.names, { active: true, typeId: 4 });
}

export function getOfficeLocation(party) {
  return get(find(party.partyOffice, { active: true }), "city");
}

export function getWorkYears(party) {
  const employeeRole = find(party.roles, { typeId: 4 });
  const startDate = get(employeeRole, "startDate", "");
  const endDate = get(employeeRole, "endDate", "");
  return [startDate.substr(0, 4), endDate.substr(0, 4)];
}

export function getAlumniProfileId(party) {
  return get(find(party.externalIds, { typeId: 51 }), "externalId");
}

export async function invite(employee, email, subject, message) {
  let { firstName, surname } = getDisplayName(employee);
  const officeLocation = getOfficeLocation(employee);
  const [startYear, endYear] = getWorkYears(employee);

  // Many ex-employee rows have first and last all in surname
  if(!firstName && (surname || "").indexOf(" ") > 0) {
    const names = surname.split(" ");
    firstName = get(names, "[0]", "");
    surname = get(names, "[1]", "");
  }

  const entry = {
    firstName,
    lastName: surname,
    email,
    status: "invited",
  };
  if (officeLocation) {
    entry.officeLocationWorkedIn = officeLocation;
  }
  if (startYear) {
    entry.startYear = parseInt(startYear);
  }
  if (endYear) {
    entry.endYear = parseInt(endYear);
  }
  entry.partyId = employee.id;

  // Create a profile
  let profile = await Profiles.create(entry);

  // turn into simple JSON
  profile = Profiles.fixUpItem(profile)
  let profileId = profile.id;
  delete profile.id;
  delete profile.company;
  delete profile.profilePicture;
  delete profile.petPhoto;
  delete profile.additionalPhotos;

  // Add the profileid to the employee
  await Parties.patch(employee.id, [
    {
      op: "add",
      path: `/externalIds/${employee.externalIds.length}`,
      value: {
        typeId: 51,
        type: "AlumniProfileId",
        externalId: profile.id
      }
    }
  ]);

  // Invite 
  const invitation = await ExternalInvitations.invite(employee.id, employee.name, email, subject, message);

  // See if this email already had an External User party
  if(invitation.partyId !== employee.id) {
    profile.partyId = invitation.partyId;
    await Profiles.updateDraft(profileId, profile);
  }
}
