import React from "react";
import { useDispatch } from "react-redux";
import ShareForms from './ShareForms';
import * as Accolades from "../../redux/accolades";

export default function AccoladePosting () {
  const dispatch = useDispatch();
  return (
    <ShareForms
      className="brag-posting"
      formName="accolade"
      header="BRAG HERE" 
      labelOne="Accolade Title"
      fieldOne="title"
      handleSubmit={(data) => dispatch(Accolades.createAccolade(data))}
      animationDuration={0.5}
      animationDelay={1}
    />
  );
}