import * as Socials from "../api/socials";
import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = [];

let socials = createSlice({
  name: "socials",
  initialState: initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setSocials: (_state, action) => {
      return action.payload;
    }
  }
});

export const { reset, setSocials } = socials.actions;

export const getSocials = createSelector(
  state => state.socials,
  socials => socials
);


export function fetchSocials() {  
  return async (dispatch) => {
    try {
      const socials = await Socials.all();
      dispatch(setSocials(socials));
    } catch (err) {
      console.log(err);
    }
  };
}

export default socials.reducer;
