import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import { map } from 'lodash';

const statusValues = ["all", "requested", "invited", "registered", "inactive", "rejected"];

const stateOptions = map(statusValues, (status, index) => ({
  key: `${status}-index`,
  text: status,
  value: status,
}))

const StatusDropdownSelection = ({ handleDropDownChange, isTyping }) => {
  const [dropdownValue, setDropdownValue] = useState("all")
  const handleChange = (e, { value }) => {
    setDropdownValue(value)
    handleDropDownChange(value)
  }

  useEffect(() => {
    if(isTyping) {
      setDropdownValue("all")
    }
  }, [isTyping])

  return (
    <Dropdown
      text="Status"
      icon="filter"
      className="icon"
      labeled
      floating
      button
      options={stateOptions}
      value={dropdownValue}
      onChange={handleChange}
    />
  )
}

export default StatusDropdownSelection;
